import { FC, useContext, useState ,useEffect} from "react";

import { IFranchise } from "../../models/IFranchise";
import { ReactComponent as Heart } from '../../assets/icons/heart.svg';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { FRANCHISE_PAGE_ROUTE } from "../../utilits/consts";
import { STATIC_URL } from "../../http";
import { formatDate } from "../../utilits/formatDate";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import ScrollToTopNavLink from "../ScrollToTopNavLink";
import CustomButton from "../buttons/CustomButton";

import classes from './FranchiseBox.module.scss';

interface FavoritesBoxProps {
    franchise: IFranchise;
    onFavoriteToggle: (franchiseId: number) => void;
}

const FavoritesBox: FC<FavoritesBoxProps> = ({ franchise, onFavoriteToggle }) => {
    const { favoritesStore } = useContext(Context);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [descriptionHtml, setDescriptionHtml] = useState<string>(franchise.description);
    
    useEffect(() => {
        if (franchise.description) {
            const contentState = convertFromRaw(JSON.parse(franchise.description));
            const editorState = EditorState.createWithContent(contentState);
            const html = stateToHTML(editorState.getCurrentContent());
            setDescriptionHtml(html);
        }
    }, [franchise.description]);

    useEffect(() => {
        setIsFavorite(favoritesStore.favorites.some(fav => fav.id === franchise.id));
    }, [favoritesStore.favorites, franchise.id]);
    
    const toggleFavorite = async () => {
        try {
            onFavoriteToggle(franchise.id);
        } catch (error) {
            console.error("Error updating favorite status:", error);
            setIsFavorite(!isFavorite);
        }
    };

    return (
        <>
            {isFavorite && (
                <div className={classes.franchiseBox__container}>
                    <img className={classes.franchiseImage} src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt={franchise.name} />
                    
                    <h2>{franchise.name}</h2>

                    <div className={classes.franchise__info}>
                        <div className={classes.creationTime}>
                            <Clock />
                            <span>{formatDate(franchise.createdAt)}</span>
                        </div>
                        <div className={classes.franchise__descripton} dangerouslySetInnerHTML={{ __html: descriptionHtml }}></div>
                    </div>
                    
                    <span className={classes.totalViews}>Всего просмотров: {franchise.views}</span>
                    
                    <div className={classes.toggle__button} onClick={toggleFavorite}>
                        <Heart className={`${classes.icon} ${isFavorite && classes.favorite__icon}`} />
                    </div>

                    <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`} className={classes.toFranchisePage__button}>
                        <CustomButton padding="1.125rem 1.5rem" >
                            Подробнее
                        </CustomButton>
                    </ScrollToTopNavLink>
                </div>
            )}
        </>
    );
};

export default observer(FavoritesBox);
import { useState } from "react";
import classes from './Questionnaire.module.scss';
import QuestionnaireInput from "../../UI/inputs/QuestionnaireInput";
import CustomButton from "../../UI/buttons/CustomButton";
import { useNotification } from "../../hooks/useNotification";
import LabeledCheckbox from "../../UI/inputs/LabledCheckbox";
import MailService from "../../service/MailService";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../utilits/consts";
import * as Yup from "yup";

interface FormData {
    fullName: string;
    phone: string;
    email: string;
    city: string;
    franchiseInterest: string;
    investmentAmount: string;
    businessExperience: string;
    leadershipExperience: string;
    franchiseExperience: string;
    hasPremises: string;
    notes: string;
    consent: boolean;
}

const initialFormState: FormData = {
    fullName: "",
    phone: "",
    email: "",
    city: "",
    franchiseInterest: "",
    investmentAmount: "",
    businessExperience: "",
    leadershipExperience: "",
    franchiseExperience: "",
    hasPremises: "",
    notes: "",
    consent: false
};

const validationSchema = Yup.object().shape({
     fullName: Yup.string().required("Это поле обязательно для заполнения"),
     phone: Yup.string()
          .matches(/^[+]?[0-9]{1,3}?[-.●]?[(]?[0-9]{1,4}[)]?[-.●]?[0-9]{1,4}[-.●]?[0-9]{1,4}$/, "Введите корректный номер телефона")
          .required("Это поле обязательно для заполнения"),
     email: Yup.string()
         .email("Введите корректный адрес электронной почты")
         .required("Это поле обязательно для заполнения"),
     city: Yup.string().required("Это поле обязательно для заполнения"),
     franchiseInterest: Yup.string().required("Это поле обязательно для заполнения"),
     investmentAmount: Yup.string().required("Это поле обязательно для заполнения"),
     businessExperience: Yup.string().required("Выберите один из вариантов"),
     leadershipExperience: Yup.string().required("Выберите один из вариантов"),
     franchiseExperience: Yup.string().required("Выберите один из вариантов"),
     hasPremises: Yup.string().required("Выберите один из вариантов"),
     consent: Yup.boolean()
         .oneOf([true], "Вы должны согласиться на обработку данных")
         .required("Вы должны согласиться на обработку данных"),
});

const Questionnaire = () => {
     const [formData, setFormData] = useState<FormData>(initialFormState);
     const [errors, setErrors] = useState<{ [key: string]: string }>({});
     const { notify, showLoading, hideLoading } = useNotification();
     const navigate = useNavigate();
 
     const validate = async (): Promise<boolean> => {
          try {
               await validationSchema.validate(formData, { abortEarly: false });
               setErrors({});
               return true;
          } catch (err) {
               const validationErrors: { [key: string]: string } = {};
               if (err instanceof Yup.ValidationError) {
                    err.inner.forEach((error) => {
                         if (error.path) validationErrors[error.path] = error.message;
                    });
               }
               setErrors(validationErrors);
               notify("Проверьте правильность заполнения формы и исправьте ошибки");
               return false;
          }
     };
 
     const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const { name, value } = e.target;
          setFormData({
               ...formData,
               [name]: value,
          });
     };
 
     const handleCheckboxChange = () => {
          setFormData({
               ...formData,
               consent: !formData.consent,
          });
     };
 
     const handleSubmit = async (e: React.FormEvent) => {
          e.preventDefault();
          const isValid = await validate();
          if (!isValid) return;
     
          try {
               showLoading("Отправляем результаты анкеты");
               await MailService.sendQuestionnaireResult(formData);
               hideLoading();
               notify("Анкета отправлена. Спасибо за ваше участие!");
               setFormData(initialFormState);
               navigate(HOME_ROUTE);
          } catch (error) {
               console.error("Ошибка при отправке анкеты:", error);
          }
     };
 
     const handleReset = () => {
          setFormData(initialFormState);
          setErrors({});
     };

     return (
          <>
               <Helmet>
                    <title>Анкета-заявка | Ваш бизнес</title>
                    <meta name="description" content="Заполните анкету для подачи заявки на сотрудничество в сфере франшиз." />
               </Helmet>
               <div className={classes.questionnaire__container}>
                    <div className={classes.questionnaire__content}>
                         <h1>Анкета-заявка потенциального франчайзи</h1>
                         <div className={classes.questionnaire__items}>
                              <QuestionnaireInput 
                                   headerName="ФИО"
                                   isRequired
                                   name="fullName"
                                   value={formData.fullName}
                                   onChange={handleInputChange}
                                   error={errors.fullName}
                              />

                              <QuestionnaireInput 
                                   headerName="Телефон"
                                   isRequired
                                   name="phone"
                                   value={formData.phone}
                                   onChange={handleInputChange}
                                   error={errors.phone}
                                   isPhone
                              />
                              
                              <QuestionnaireInput 
                                   headerName="E-mail"
                                   isRequired
                                   name="email"
                                   value={formData.email}
                                   onChange={handleInputChange}
                                   error={errors.email}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Город, регион для открытия точки"
                                   isRequired
                                   name="city"
                                   value={formData.city}
                                   onChange={handleInputChange}
                                   error={errors.city}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Франшиза в какой сфере Вас интересует?"
                                   isRequired
                                   name="franchiseInterest"
                                   value={formData.franchiseInterest}
                                   onChange={handleInputChange}
                                   error={errors.franchiseInterest}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Располагаемая сумма инвестиций для организации и ведения бизнеса"
                                   isRequired
                                   name="investmentAmount"
                                   value={formData.investmentAmount}
                                   onChange={handleInputChange}
                                   error={errors.investmentAmount}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Есть ли у Вас опыт самостоятельного ведения бизнеса?"
                                   headerInfo="(для физического лица)"
                                   isRequired
                                   isYesNo
                                   name="businessExperience"
                                   value={formData.businessExperience}
                                   onChange={handleInputChange}
                                   error={errors.businessExperience}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Есть ли опыт в руководстве персоналом?"
                                   headerInfo="(для физического лица)"
                                   isYesNo
                                   name="leadershipExperience"
                                   value={formData.leadershipExperience}
                                   onChange={handleInputChange}
                                   error={errors.leadershipExperience}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Есть ли у Вас опыт работы в сфере интересуемой Вас франшизы?"
                                   headerInfo="Например, если интересует франшиза в сфере общественного питания, есть ли опыт работы в этой сфере."
                                   isRequired
                                   isYesNo
                                   name="franchiseExperience"
                                   value={formData.franchiseExperience}
                                   onChange={handleInputChange}
                                   error={errors.franchiseExperience}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Имеется ли у Вас помещение для открытия торговой точки?"
                                   headerInfo="Если да, то в примечании ниже укажите его характеристики и месторасположение.<br/>
                                   Если нет, то в примечании укажите, нужна ли Вам помощь в подборе помещения."
                                   isRequired
                                   isYesNo
                                   name="hasPremises"
                                   value={formData.hasPremises}
                                   onChange={handleInputChange}
                                   error={errors.hasPremises}
                              />
                              
                              <QuestionnaireInput 
                                   headerName="Ваши примечания"
                                   name="notes"
                                   value={formData.notes}
                                   onChange={handleInputChange}
                              />
                              
                              <LabeledCheckbox text="Я согласен на обработку моих данных" checked={formData.consent} onChange={handleCheckboxChange} />
                         </div>
                         <div className={classes.questionnaire__buttons}>
                              <CustomButton onClick={handleReset} bodyType="empty">Очистить форму</CustomButton>
                              <CustomButton onClick={handleSubmit}>Отправить</CustomButton>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default Questionnaire;
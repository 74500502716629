import { toJS } from "mobx";
import $api from "../http/index"
import { IFranchise } from "../models/IFranchise";

export default class FranchiseApprovalService {
    
    static async fetchApprovalFranchise(): Promise<IFranchise[]> {
        try {
            const { data } = await $api.get('/approval-franchises/get-all');
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async fetchFranchisesWithStatus(): Promise<IFranchise[]> {
        try {
            const { data } = await $api.get('/approval-franchises/get-all-with-status');
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async approveFranchise(franchiseId: number) {
        try {
            const { data } = await $api.post(`/approval-franchises/approve/${franchiseId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }
    
    static async denyFranchise(franchiseId: number) {
        try {
            const { data } = await $api.post(`/approval-franchises/deny/${franchiseId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async removeFranchiseFromPool(franchiseIds: number[]) {
        try {
            const { data } = await $api.post('/approval-franchises/remove-from-pool', { franchiseIds });
            return data;
        } catch (error) {
            throw error;
        }
    }
    

    static async addFranchiseToPool(franchiseId:number){
        try {
            const { data } = await $api.post(`/approval-franchises/add-to-pool/${franchiseId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }
}
import React, { useEffect, useState } from 'react';
import { ISubscription } from '../../models/ISubscription';
import classes from './AndminPageForms.module.scss';
import MailService from '../../service/MailService';

const SubscribersManagment = () => {
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await MailService.getAllSubscribers();
                setSubscriptions(response);
            } catch (error) {
                console.error("Ошибка загрузки подписок:", error);
            }
        };
        fetchSubscriptions();
    }, []);

    return (
        <div className={classes.adminDashboard__container}>
            <div className={classes.adminDashboard__content}>
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Текущие подписки на рассылку</h1>
                        <table className={classes.subscriptionsTable}>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Статус</th>
                                    <th>Дата подписки</th>
                                    <th>Дата отписки</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subscriptions.map((sub) => (
                                    <tr key={sub.id}>
                                        <td data-label="Email">{sub.email}</td>
                                        <td data-label="Статус">{sub.status}</td>
                                        <td data-label="Дата подписки">{new Date(sub.subscribedAt).toLocaleDateString()}</td>
                                        <td data-label="Дата отписки">{sub.unsubscribedAt ? new Date(sub.unsubscribedAt).toLocaleDateString() : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscribersManagment;
import classes from './FindYourFranchise.module.scss'
import QuestionnaireButton from "../../../../UI/buttons/QuestionnaireButton";
import useResponsive from '../../../../hooks/useResponsive';

const FindYourFranchise = () => {
    const { isMobile } = useResponsive();
    
    return (
        <div className={classes.findYourFranchise__container}>
            <div className={classes.findYourFranchise__content}>
                {!isMobile ? (
                    <h1>
                        Ищете<br />
                        <span>подходящую</span><br />
                        франшизу?
                    </h1>
                ) : (
                    <h1>
                        Ищете
                        <span> подходящую </span>
                        франшизу?
                    </h1>
                )}
                <div className={classes.questionaryBox}>
                    <h3>Мы можем помочь! Ответьте на несколько вопросов, и мы подберем для вас лучшие варианты.</h3>
                    <QuestionnaireButton/>
                </div>
            </div>
        </div>
    )
}

export default FindYourFranchise;
import { useState, useContext } from 'react';

import { Context } from '../..';
import { useNotification } from '../../hooks/useNotification';
import { EditorState, convertToRaw } from 'draft-js';
import FileUploader from '../../UI/fileUploaders/FileUploader';
import CustomButton from '../../UI/buttons/CustomButton';
import DraftEditor from '../../UI/inputs/DraftEditor';
import InputField from '../../UI/inputs/InputField/InputField';
import * as Yup from 'yup';

import classes from './AndminPageForms.module.scss';

const NewsCreator = () => {
    const { newsStore } = useContext(Context);
    const { notify, showLoading, hideLoading } = useNotification();
    
    const [frontImage, setFrontImage] = useState<File | null>(null);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<{ [key: string]: string }>({
        id: '',
        title: '',
        img: '',
        details: '',
        content: '',
    });

    const validationSchema = Yup.object({
        title: Yup.string().required('Это обязательное поле'),
        details: Yup.string().required('Это обязательное поле'),
        img: Yup.mixed().required('Это обязательное поле'),
        content: Yup.string().required('Это обязательное поле'),
    });

    const handleFrontImageChange = (newImage: File) => {
        setFrontImage(newImage);
        setFormData({ ...formData, img: '' });
        setErrors({ ...errors, img: '' });
    };

    const handleEditorContentChange = (content: string) => {
        setFormData({ ...formData, content });
        setErrors({ ...errors, content: '' });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, field: keyof typeof formData) => {
        const value = e.target.value;
        setFormData({ ...formData, [field]: value });
        setErrors({ ...errors, [field]: '' });
    };

    const clearFormData = () => { 
        setFormData({
            id: '',
            title: '',
            img: '',
            details: '',
            content: '',
        });
        setFrontImage(null);
        setEditorState(EditorState.createEmpty());
        setErrors({});
    };

    const validateForm = async (): Promise<boolean> => {
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            if (!frontImage) throw new Error("img:Это обязательное поле");
            return true;
        } catch (validationErrors) {
            const formattedErrors: { [key: string]: string } = {};
            if (validationErrors instanceof Yup.ValidationError) {
                validationErrors.inner.forEach((error) => {
                    if (error.path) {
                        formattedErrors[error.path] = error.message;
                    }
                });
            }
            if (validationErrors instanceof Error && validationErrors.message.startsWith("img:")) {
                formattedErrors.img = validationErrors.message.split(":")[1];
            }
            setErrors(formattedErrors);
            return false;
        }
    };

    const handleSubmit = async () => {
        if (!(await validateForm())) {
            return;
        }

        try {
            showLoading("Создаем новость");
            const contentState = editorState.getCurrentContent();
            const contentJSON = JSON.stringify(convertToRaw(contentState));
            await newsStore.createNews(
                formData.title,
                frontImage!,
                formData.details.replace(/\n/g, '<br />'),
                contentJSON
            );
            await newsStore.fetchNews();
            clearFormData();
            hideLoading();
            notify(`Новость "${formData.title}" успешно создана!`);
        } catch (error) {
            console.error("Не удалось создать новость:", error);
            notify(`Не удалось создать новость: ${error}`);
        }
    };

    const handleDelete = async () => {
      

        try {
            showLoading("Удаляем новость");
            await newsStore.deleteOneNews(Number(formData.id));
            await newsStore.fetchNews();
            clearFormData();
            hideLoading();
            notify(`Новость успешно удалена!`);
        } catch (error) {
            console.error("Не удалось удалить новость ", error);
            notify(`Не удалось удалить новость: ${error}`);
        }
    };

    return (
        <div className={classes.adminDashboard__container}>
            <div className={classes.adminDashboard__content}>
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Создание Новости</h1>
                        <div className={classes.adminTool__newsCreatorBox}>
                            <FileUploader
                                width='clamp(200px, 15vw, 270px)'
                                height='clamp(200px, 15vw, 270px)'
                                onImageChange={handleFrontImageChange}
                                error={!!errors.img}
                                errorMessage={errors.img}
                            />
                            <InputField
                                as="input"
                                type="text"
                                maxLength={200}
                                placeholder='Заголовок'
                                value={formData.title}
                                onChange={(e) => handleInputChange(e, 'title')}
                                error={!!errors.title}
                                errorMessage={errors.title}
                            />
                            <InputField
                                as='textarea'
                                maxLength={512}
                                placeholder='Краткое описание'
                                value={formData.details}
                                onChange={(e) => handleInputChange(e, 'details')}
                                error={!!errors.details}
                                errorMessage={errors.details}
                            />
                        </div>
                        <h2>Описание</h2>
                        <DraftEditor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            onContentChange={handleEditorContentChange}
                            error={!!errors.content}
                            errorMessage={errors.content}
                            maxLength={1024}
                        />
                        <CustomButton onClick={handleSubmit}>Создать новость</CustomButton>
                    </div>
                </div>

                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Удаление новости</h1>
                        <div className={classes.adminToolBox__controllers}>
                            <InputField
                                as='select'
                                value={formData.id || ''}
                                onChange={(e) => handleInputChange(e, 'id')}
                                error={!!errors.id}
                                errorMessage={errors.id}
                            >
                                <option value="">Выберите новость, которую вы хотите удалить</option>
                                {newsStore.News.map((news) => (
                                    <option key={news.id} value={news.id}>{news.title}</option>
                                ))}
                            </InputField>
                        </div>
                        <CustomButton onClick={handleDelete}>Удалить новость</CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsCreator;
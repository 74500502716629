import React from "react";
import AuthorizationInput from "../../../UI/inputs/AuthorizationInput";

import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/icons/eye-off.svg';

interface RegistrFirstStepProps {
    login: string;
    loginError?: boolean;

    password: string;
    passwordError?: boolean;

    confirmPassword: string;
    confirmPasswordError?: boolean;

    blurHandle: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isSubmitClicked: boolean;
}

const RegistrFirstStep: React.FC<RegistrFirstStepProps> = ({
    login,
    loginError,

    password,
    passwordError,

    confirmPassword,
    confirmPasswordError,

    handleChange,
    blurHandle,
    isSubmitClicked
}) => {
    return (
        <>
            <AuthorizationInput
                type="text"
                placeholder="Логин"
                name="login"
                value={login}
                onBlur={blurHandle}
                onChange={handleChange}
                icon={<ProfileIcon />}
                hintMessage="Как должен выглядеть логин:"
                hints={[
                    "Длина логина: от 4 до 20 символов",
                    "Буквы латинского алфавита и цифры",
                    "Не содержит пробелы и специальные символы"
                ]}
                error={isSubmitClicked && loginError}
            />

            <AuthorizationInput 
                type="password" 
                placeholder="Пароль" 
                name="password"
                value={password}
                onBlur={blurHandle}
                onChange={handleChange}
                error={isSubmitClicked && passwordError}
                toggleIcon={<EyeIcon />}
                toggleIconActive={<EyeOffIcon />}
                hintMessage="Пароль должен содержать как минимум:"
                hints={[
                    "8 символов",
                    "1 цифру",
                    "1 строчную букву"
                ]}
                isPasswordField
            />

            <AuthorizationInput
                type="password" 
                placeholder="Повторите пароль" 
                name="confirmPassword"
                value={confirmPassword}
                onBlur={blurHandle}
                onChange={handleChange}
                error={isSubmitClicked && confirmPasswordError}
                toggleIcon={<EyeIcon />}
                toggleIconActive={<EyeOffIcon />}
                isPasswordField
            />
        </>
    );
};

export default RegistrFirstStep;

import { FC, useContext, useEffect, useState } from 'react';
import classes from './FranchiseModal.module.scss';
import { IFranchise } from '../../../models/IFranchise';
import { STATIC_URL } from '../../../http';
import CustomButton from '../../../UI/buttons/CustomButton';
import LabeledCheckbox from '../../../UI/inputs/LabledCheckbox';
import { useNotification } from '../../../hooks/useNotification';
import { Context } from '../../../index';
import MailService from '../../../service/MailService';
import classNames from 'classnames';
import PhoneInput from '../../../UI/inputs/PhoneInput';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import InputField from '../../../UI/inputs/InputField/InputField';

interface FranchiseModalProps {
     franchise: IFranchise;
     onClose: () => void;
     isOpen: boolean;
}

interface RequiredFieldsMissing {
     fullName?: boolean;
     email?: boolean;
     phone?: boolean;
     consent?: boolean;
}

const FranchiseModal: FC<FranchiseModalProps> = ({ 
     isOpen,
     onClose,
     franchise 
}) => {
     const { userStore } = useContext(Context);
     const { notify } = useNotification();
     
     const [formData, setFormData] = useState({
          fullName: userStore.user.firstName || '',
          email: userStore.user.email || '',
          phone: '',
          message: '',
          consent: false,
     });
     
     const [errors, setErrors] = useState<RequiredFieldsMissing>({});
     const [isClosing, setIsClosing] = useState(false);

     useEffect(() => {
          document.body.style.overflow = isOpen ? 'hidden' : '';
          return () => { document.body.style.overflow = ''; };
     }, [isOpen]);

     const handleClose = () => {
          setIsClosing(true);
          setTimeout(() => {
               onClose();
               setIsClosing(false);
               setErrors({});
          }, 300);
     };
     
     
     const validate = (): RequiredFieldsMissing => {
          const { fullName, email, phone, consent } = formData;
          const newErrors: RequiredFieldsMissing = {};
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
          if (!fullName.trim()) {
              newErrors.fullName = true;
              notify("Пожалуйста, введите ваше ФИО");
          }
      
          if (!email.trim() || !emailPattern.test(email)) {
              newErrors.email = true;
              notify("Пожалуйста, введите корректную почту");
          }
      
          const numericValue = phone.replace(/\D/g, '');
          const phoneNumber = parsePhoneNumberFromString(`+${numericValue}`);
          if (!phoneNumber || !phoneNumber.isValid()) {
              newErrors.phone = true;
              notify("Пожалуйста, введите корректный номер телефона");
          }
      
          if (!consent) {
              newErrors.consent = true;
              notify('Пожалуйста, согласитесь на обработку данных');
          }
      
          return newErrors;
     };

     const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const { name, value } = e.target;
          
          setFormData((prevData) => ({
               ...prevData,
               [name]: value,
          }));

          if ((name as keyof RequiredFieldsMissing) in errors) {
               setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name as keyof RequiredFieldsMissing]: value.trim() ? false : prevErrors[name as keyof RequiredFieldsMissing],
               }));
          }
     };

     const handlePhoneChange = (value: string) => {
          setFormData(prevData => ({ ...prevData, phone: value }));
     };     

     const handleCheckboxChange = () => setFormData(prevData => ({ ...prevData, consent: !prevData.consent }));

     const handleSubmit = (e: React.FormEvent) => {
          e.preventDefault();
          const validationErrors = validate();
          setErrors(validationErrors);
          if (!Object.keys(validationErrors).length) sendFormData();
     };

     const sendFormData = async () => {
          try {
               await MailService.sendFranchiseRequest(formData.fullName, formData.email, formData.phone.toString(), formData.message, franchise.id);
               notify("Спасибо! Ваша заявка успешно отправлена.");
               setFormData({ fullName: '', email: '', phone: '', message: '', consent: false });
               handleClose();
          } catch {
               notify("Произошла ошибка при отправке заявки. Попробуйте позже.");
          }
     };

     if (!isOpen && !isClosing) return null;

    return (
          <div className={classNames(classes.modalOverlay, 
               { 
                    [classes.open]: isOpen && !isClosing,
                    [classes.closing]: isClosing
               })} 
               onClick={handleClose}
          >
               <div className={classNames(classes.franchiseRequest__container, 
                    {
                         [classes.open]: isOpen && !isClosing,
                         [classes.closing]: isClosing
                    })} 
                    onClick={(e) => e.stopPropagation()}
               >
                    <div className={classes.franchiseRequest__header}>
                         <h2>{franchise.name}</h2>
                         <button className={classes.closeButton} onClick={handleClose} aria-label="Закрыть модальное окно">×</button>
                    </div>
                    
                    <div className={classes.franchiseRequest__body}>
                         <img className={classes.franchiseImage} src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt={franchise.name || 'Franchise Image'} />
                         <form className={classes.franchiseForm} onSubmit={handleSubmit}>
                              <InputField name="fullName" maxLength={200} value={formData.fullName} onChange={handleChange} placeholder="Введите ваше имя"                   as="input" error={!!errors.fullName} />
                              <InputField name="email"    maxLength={200} value={formData.email}    onChange={handleChange} placeholder="Введите вашу почту"                 as="input" error={!!errors.email} />
                              <InputField name="message"  maxLength={250} value={formData.message}  onChange={handleChange} placeholder="Здесь вы можете оставить сообщение" as="textarea" style={{ minHeight: '100px' }} />
                              <PhoneInput value={formData.phone} onChange={handlePhoneChange} error={!!errors.phone} />
                              <LabeledCheckbox text="Я согласен на обработку моих данных" checked={formData.consent} onChange={handleCheckboxChange} />
                              <CustomButton type="submit" padding="1rem 2rem" style={{ alignSelf: 'end', marginTop: "auto" }}>Оставить заявку</CustomButton>
                         </form>
                    </div>
               </div>
          </div>
     );
};

export default FranchiseModal;
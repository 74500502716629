import { RELEASE_CREATION_ROUTE,NEWS_CREATION_ROUTE, INDUSTRY_CREATION_ROUTE, TYPE_CREATION_ROUTE, PENDING_FRANCHISES_ROUTE, SUBSCRIBERS_LIST_ROUTE, FRANCHISE_STATUS_MANAGER_ROUTE } from "../../utilits/consts";

import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";
import CustomButton from "../../UI/buttons/CustomButton";

import classes from './Admin.module.scss'

const Admin = () => {
    return (
        <div className={classes.adminPage__container}>
            <div className={classes.adminPage__content}>
                <div className={classes.admin__tool}>
                    <h1>Управление индустриями</h1>
                    <ScrollToTopNavLink to={INDUSTRY_CREATION_ROUTE}>
                        <CustomButton bodyType="empty">
                            Перейти к управлению
                        </CustomButton>
                    </ScrollToTopNavLink>           
                </div>
                <div className={classes.admin__tool}>
                    <h1>Управление подотраслями</h1>
                    <ScrollToTopNavLink to={TYPE_CREATION_ROUTE}>
                        <CustomButton bodyType="empty">
                            Перейти к управлению
                        </CustomButton>
                    </ScrollToTopNavLink>                    
                </div>
                <div className={classes.admin__tool}>
                    <h1>Управление новостями</h1>
                    <ScrollToTopNavLink to={NEWS_CREATION_ROUTE}>
                        <CustomButton bodyType="empty">
                            Перейти к управлению
                        </CustomButton>
                    </ScrollToTopNavLink>                      
                </div>
                <div className={classes.admin__tool}>
                    <h1>Управление редакцией</h1>
                    <ScrollToTopNavLink to={RELEASE_CREATION_ROUTE}>
                        <CustomButton bodyType="empty">
                            Перейти к управлению
                        </CustomButton>
                    </ScrollToTopNavLink>                      
                </div>
                <div className={classes.admin__tool}>
                    <h1>Франшизы на рассмотрении</h1>
                    <ScrollToTopNavLink to={PENDING_FRANCHISES_ROUTE}>
                        <CustomButton bodyType="empty">
                            Перейти к управлению
                        </CustomButton>
                    </ScrollToTopNavLink>                      
                </div>
                <div className={classes.admin__tool}>
                    <h1>Управление статустом франшизы</h1>
                    <ScrollToTopNavLink to={FRANCHISE_STATUS_MANAGER_ROUTE}>
                        <CustomButton bodyType="empty">
                            Перейти к управлению
                        </CustomButton>
                    </ScrollToTopNavLink>                      
                </div>
                <div className={classes.admin__tool}>
                    <h1>Текущие подписки на рассылку</h1>
                    <ScrollToTopNavLink to={SUBSCRIBERS_LIST_ROUTE}>
                        <CustomButton bodyType="empty">
                            Перейти к управлению
                        </CustomButton>
                    </ScrollToTopNavLink>                      
                </div>
            </div>
        </div>
    )
}

export default Admin;
import { IDescriptionBlock } from "../models/IDescriptionBlock";
import FranchiseDescriptionService from "../service/FranchiseDescriptionService";

export default class FranchiseDescriptionStore{
    async fetchFranchiseDescriptions(id: number): Promise<IDescriptionBlock[]> {
        try {
            const blocks = await FranchiseDescriptionService.fetchDescriptionBlocks(id);
            return blocks;
        } catch (error) {
            console.error("Не удалось получить описание ", error);
            throw error;
        }
    }
}
import { runInAction, toJS } from "mobx";
import $api from "../http/index";
import { IFranchise } from "../models/IFranchise";
import internal from "stream";
import { IDescriptionBlock } from "../models/IDescriptionBlock";

export default class FranchiseDescriptionService {
    
    static async addBlockToDescription (
        form: {
            description: string,
            galleryImages: File[],
        },
        franchiseId:number
    ) {
        const formData = new FormData();
        formData.append('description', form.description);
    
        form.galleryImages.forEach(file => {
            formData.append('galleryImages', file); 
        });

        const response = await $api.post(`/descriptions-franchises/add/${franchiseId}`, formData,  {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    }

    static async updateDescription(franchiseId:number){
     
        return ;
    }

    static async getFranchiseDescription(franchiseId:number) {
        try {
            const { data } = await $api.get(`/descriptions-franchises/get-franchise-description/${franchiseId}`);
            return data; 
        } catch (error) {
            throw error;
        }
    }

    static async fetchDescriptionBlocks(franchiseId: number): Promise<IDescriptionBlock[]> {
        try {
            const { data } = await $api.get(`/descriptions-franchises/get-all-blocks/${franchiseId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async updateDescriptionBlock(franchiseId: number, descriptionBlockId:number){
        try {
            const { data } = await $api.get(`/descriptions-franchises/update-dranchise-description/${franchiseId}/${descriptionBlockId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }


    static async fetchOneBlock(franchiseId:number,descriptionBlockId:number){
        try {
            const { data } = await $api.get(`/descriptions-franchises/get-block/${franchiseId}/${descriptionBlockId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async deleteDescriptionBlock(franchiseId:number,descriptionBlockId:number){
        try {
            const { data } = await $api.get(`/descriptions-franchises/delete-block/${franchiseId}/${descriptionBlockId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }
}


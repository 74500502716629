import { useState, useContext, useEffect } from "react";

import Pagination from "../../UI/pagination/Pagination";
import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";
import CatalogFranchiseCard from "../../UI/cards/CatalogFranchiseCard";
import LoadingDots from "../../UI/loadingDots";
import FilterButton from "../../UI/buttons/FilterButton";
import NothingFound from "../NothingFoundPage/NothingFound";
import AllFiltersButton from "../../UI/buttons/AllFiltersButton";
import CustomButton from "../../UI/buttons/CustomButton";

import { useLayoutEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { IIndustry } from "../../models/IIndustry";
import { useLocation } from "react-router-dom";
import { FRANCHISE_CATALOG_ROUTE } from "../../utilits/consts";

import classes from "./FranchisesCatalog.module.scss";
import { Helmet } from "react-helmet";
import useResponsive from "../../hooks/useResponsive";
import FilterSwiper from "../../UI/swipers/FilterSwiper";

const FranchisesCatalog = () => {
     const location = useLocation();
     const isCatalogPage = location.pathname === FRANCHISE_CATALOG_ROUTE;
     const { franchiseStore, industryStore, typeOfActivityStore } = useContext(Context);

     const { isMobile, isTablet, isSmallDesktop, isMediumDesktop, isLargeDesktop } = useResponsive();
     useLayoutEffect(() => {
          switch (true) {
               case isMobile:
               case isTablet:
                    franchiseStore.setLimit(6);
                    break;
               case isLargeDesktop:
                    franchiseStore.setLimit(10);
                    break;
               default:
                    franchiseStore.setLimit(8);
                    break;
          }
     }, [isMobile, isTablet, isSmallDesktop, isMediumDesktop, isLargeDesktop, franchiseStore]);
 
     const totalPages = Math.ceil(franchiseStore.totalCount / (franchiseStore.limit || 1));

     const [filters, setFilters] = useState({
          budget: "Все",
          categories: industryStore.getSelectedIndustryId ? [industryStore.getSelectedIndustryId] : [],
          types: typeOfActivityStore.getSelectedTypeId ? [typeOfActivityStore.getSelectedTypeId] : [],
          showAllCategories: industryStore.getSelectedIndustryId === 0,
     });

     const [sort, setSort] = useState<string>("По популярности");

     useEffect(() => {
          franchiseStore.setFilters(filters.categories, filters.types, filters.budget, sort);
     }, [filters, sort, franchiseStore]);

     const handleFilterChange = (category: number, checked: boolean) => {
          setFilters((prevFilters) => {
               let newCategories = [...prevFilters.categories];
               if (category === 0) {
                    newCategories = checked ? [] : [0];
               } else {
                    newCategories = checked ? [...newCategories, category] : newCategories.filter((c) => c !== category);
               }
               return {
                    ...prevFilters,
                    categories: newCategories,
                    showAllCategories: newCategories.length === 0 || newCategories.includes(0),
               };
          });
     };

     const handleBudgetChange = (budget: string) => {
          setFilters((prevFilters) => ({ ...prevFilters, budget }));
     };

     const handleSortChange = (sort: string) => {
          setSort(sort);
     };

     const handleTypeChange = (types: number[]) => {
          setFilters((prevFilters) => ({
              ...prevFilters,
              types,
          }));
      };

     const handlePageChange = (page: number) => {
          franchiseStore.fetchCatalogPage(page);
     };

     return (
          <>
               {isCatalogPage && (
                    <Helmet>
                         <title>Каталог франшиз</title>
                         <meta name="description" content="Выберите франшизы по категориям и фильтрам. Найдите идеальный бизнес для вас!" />
                    </Helmet>
               )}
               <div className={classes.catalog__container}>
                    <div className={classes.catalog__content}>
                         <div className={classes.catalog__header}>
                              <h1>Все франшизы</h1>
                              <div className={classes.industrieFilters__wrapper}>
                                   {isMobile ? (
                                        <FilterSwiper
                                             filters={filters}
                                             handleFilterChange={handleFilterChange}
                                        />
                                   ) : (
                                        <>
                                             <FilterButton
                                                  isActive={filters.showAllCategories}
                                                  onClick={() => handleFilterChange(0, true)}
                                             >
                                             Все категории
                                             </FilterButton>
          
                                             {industryStore.industries.map((industry: IIndustry) => (
                                                  <FilterButton
                                                       key={industry.id}
                                                       isActive={filters.categories.includes(industry.id)}
                                                       onClick={() =>
                                                            handleFilterChange(
                                                                 industry.id,
                                                                 !filters.categories.includes(industry.id)
                                                            )
                                                       }
                                                  >
                                                       {industry.name}
                                                  </FilterButton>
                                             ))}
                                        </>
                                   )}
                              </div>
                              <div className={classes.allFilters__button}>
                                   <AllFiltersButton
                                        onBudgetChange={handleBudgetChange}
                                        onSortChange={handleSortChange}
                                        onTypeChange={handleTypeChange}
                                        industries={industryStore.industries.map(industry => ({
                                             id: industry.id,
                                             name: industry.name,
                                             types: typeOfActivityStore.types.filter(type => type.industryId === industry.id)
                                        }))}
                                   />
                              </div>
                         </div>
                         {franchiseStore.isLoading ? (
                              <div className={classes.catalog__franchisesContainer}>
                                   <LoadingDots />
                              </div>
                              ) : franchiseStore.franchises.length === 0 ? (
                                   <NothingFound text="К сожалению, франшизы не найдены. Попробуйте изменить критерии поиска или обратитесь в службу поддержки." />
                              ) : (
                              <div className={classes.catalog__franchisesWrapper}>
                                   {franchiseStore.franchises.map((franchise) => (
                                        <CatalogFranchiseCard key={franchise.id} franchise={franchise} />
                                   ))}
                              </div>
                         )}

                         <div className={classes.catalog__bottom}>
                              {!isCatalogPage ? (
                                   <ScrollToTopNavLink to={FRANCHISE_CATALOG_ROUTE}>
                                        <CustomButton bodyType="empty" style={{ display: "flex", margin: "0 auto" }}>
                                             Больше франшиз
                                        </CustomButton>
                                   </ScrollToTopNavLink>
                              ) : franchiseStore.franchises.length !== 0 &&(
                                   <Pagination
                                        currentPage={franchiseStore.page}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                   />
                              )}
                         </div>
                    </div>
               </div>
          </>
     );
};

export default observer(FranchisesCatalog);

import WelcomeSection from "./HomePageComponents/WelcomeSection/WelcomeSection";
import FindYourFranchise from "./HomePageComponents/FindYourFranchise/FindYourFranchise";
import BuyAndSell from "./HomePageComponents/BuyAndSell/BuyAndSell";
import FranchisesCatalog from "../FranchisesCatalogPage/FranchisesCatalog";
import RecommendedFranchises from "./HomePageComponents/RecommendedFranchises/RecommendedFranchises";

const Home = () => {
    return (
        <>
            <WelcomeSection />
            <FindYourFranchise />
            <RecommendedFranchises />
            <FranchisesCatalog />
            <BuyAndSell />
        </>
    );
};

export default Home;

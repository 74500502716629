import React, { FC } from 'react';
import { InputProps } from './types';
import classes from './InputField.module.scss';

const InputComponent: FC<InputProps> = ({ maxLength, infoType, ...props }) => {
     const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
     if (props.type === 'number' && ['e', 'E', '-'].includes(e.key)) 
          e.preventDefault();
     };

     const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (props.type === 'number' && maxLength && e.target.value.length > maxLength) {
               e.target.value = e.target.value.slice(0, maxLength);
          }
     };

     return (
     <div className={classes.inputWrapper}>
          <input
               {...props}
               maxLength={props.type === 'number' ? undefined : maxLength}
               onKeyDown={props.type === 'number' ? handleKeyDown : undefined}
               onInput={props.type === 'number' ? handleInput : undefined}
               className={classes.input}
          />
          {infoType && <span className={classes.percentageSymbol}>{infoType}</span>}
     </div>
     );
};

export default InputComponent;

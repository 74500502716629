import { FC, useEffect, useState } from 'react';
import classes from './MultiSelect.module.scss';
import InputField from '../inputs/InputField/InputField';

interface MultiSelectProps {
    options: { value: number; label: string }[];
    selectedValues: number[];
    onChange: (selectedValues: number[]) => void;
    limit?: number;
    enumerate?: boolean;
    enableSearch?: boolean;
}

const MultiSelect: FC<MultiSelectProps> = ({
    options,
    selectedValues,
    onChange,
    limit = Infinity,
    enumerate = false,
    enableSearch = false,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);

    const handleCheckboxChange = (value: number) => {
        const isSelected = selectedValues.includes(value);
        let updatedSelectedValues;

        if (isSelected) {
            updatedSelectedValues = selectedValues.filter(val => val !== value);
        } else {
            if (selectedValues.length < limit) {
                updatedSelectedValues = [...selectedValues, value];
            } else {
                alert(`Вы можете установить только ${limit} франшиз.`);
                return;
            }
        }

        onChange(updatedSelectedValues);
    };

    useEffect(() => {
        const validSelectedValues = selectedValues.filter(value =>
            options.some(option => option.value === value)
        );
        if (validSelectedValues.length !== selectedValues.length) {
            onChange(validSelectedValues);
        }
    }, [options, selectedValues, onChange]);

    useEffect(() => {
        if (enableSearch) {
            const filtered = options.filter(option =>
                option.label.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(options);
        }
    }, [searchTerm, options, enableSearch]);

    return (
        <div className={classes.multiSelect}>
            {enableSearch && (
                <div className={classes.multiSelect__header}>
                    <InputField
                        type="text"
                        placeholder="Поиск..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            )}
            <div className={classes.multiSelect__optionsBody}>
                {filteredOptions.map(option => {
                    const selectedIndex = selectedValues.indexOf(option.value);
                    return (
                        <label key={option.value} className={classes.optionLabel}>
                            <input
                                type="checkbox"
                                value={option.value}
                                checked={selectedValues.includes(option.value)}
                                onChange={() => handleCheckboxChange(option.value)}
                                className={classes.checkbox}
                            />
                            {option.label}
                            {enumerate && selectedIndex > -1 && (
                                <span className={classes.enumerate}>{selectedIndex + 1}</span>
                            )}
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default MultiSelect;
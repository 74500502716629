import { FC, useContext, useState, useEffect, useRef, ChangeEvent, MouseEvent as ReactMouseEvent } from "react";
import classes from './SearchInput.module.scss';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import { Context } from "../../index";
import SearchItem from "./SearchItem";
import useResponsive from "../../hooks/useResponsive";
import SimpleArrow from '../../assets/UI/arrows/simple-arrow.png'
interface SearchInputProps {
    isVisible: boolean;
}

const SearchInput: FC<SearchInputProps> = ({ isVisible }) => {
    const { franchiseStore } = useContext(Context);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredData, setFilteredData] = useState(franchiseStore.franchises);
    const [showResults, setShowResults] = useState<boolean>(false);
    
    const [searchInputVisible, setSearchInputVisible] = useState<boolean>(false);
    
    const searchRef = useRef<HTMLDivElement>(null);
    const mouseDownRef = useRef<boolean>(false);

    const { isMobile } = useResponsive();
    useEffect(() => {
        if (searchTerm.trim() !== '') {
            const filtered = franchiseStore.franchises.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            if (filtered.length > 0) {
                setFilteredData(filtered);
                setShowResults(true);
            }
        } else {
            setShowResults(false);
        }
    }, [searchTerm]);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleInputClick = () => {
        if (searchTerm.trim() !== '') {
            setShowResults(true);
        }
    };

    const handleMouseDownOutside = (event: MouseEvent) => {
        if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
            mouseDownRef.current = true;
        }
    };

    const handleMouseUpOutside = (event: MouseEvent) => {
        if (mouseDownRef.current && searchRef.current && !searchRef.current.contains(event.target as Node)) {
            setShowResults(false);
            setSearchInputVisible(false);
        }
        mouseDownRef.current = false;
    };

    const handleWindowBlur = () => {
        setShowResults(false);
        setSearchInputVisible(false);
    };

    const handleSearchInputVisible = () => {
        setSearchInputVisible(!searchInputVisible);
    }

    useEffect(() => {
        window.addEventListener('blur', handleWindowBlur);
        document.addEventListener('mousedown', handleMouseDownOutside);
        document.addEventListener('mouseup', handleMouseUpOutside);

        return () => {
            window.removeEventListener('blur', handleWindowBlur);
            document.removeEventListener('mousedown', handleMouseDownOutside);
            document.removeEventListener('mouseup', handleMouseUpOutside);
        };
    }, []);

    const handleItemClick = (event: ReactMouseEvent) => {
        event.preventDefault();
        setTimeout(() => setShowResults(false), 0);
    };

    return (
        <>
            {isMobile && (
                <button className={classes.searchButton} onClick={handleSearchInputVisible}>
                    <SearchIcon className={classes.searchIcon}/>
                </button>
            )}
            <div className={`${classes.search__wrapper} ${searchInputVisible && classes.searchInputActive}`} ref={searchRef}>
                <div className={`${classes.searchInput} ${searchInputVisible && classes.open}`} ref={searchRef}>
                    {isMobile && searchInputVisible && (
                        <button className={classes.closeButton} onClick={handleSearchInputVisible}>
                            <img src={SimpleArrow} className={classes.closeButton__icon} alt="Закрыть"/>
                        </button>
                    )}
                    <input
                        className={`${classes.inputField} ${showResults && classes.inputFieldActive}`}
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleInputClick}
                        placeholder="Поиск..."
                    />
                    <SearchIcon className={classes.searchIcon}/>
                </div>
                {((isMobile && searchInputVisible && showResults) || (!isMobile && showResults)) && (
                    <ul className={`${classes.searchResults} ${isVisible ? classes.active : classes.hidden}`}>
                        {filteredData.map(item => (
                            <SearchItem
                                key={item.id}
                                franchise={item}
                                onClick={handleItemClick}
                            />
                        ))}
                    </ul>
                )}
          
            </div>
        </>
    )
};

export default SearchInput;
import { FC, useEffect } from 'react'
import classes from './ImageModal.module.scss'
import "../../styles/App.module.scss";

interface ImageModalProps {
      image: string;
      onClose: () => void;
}

const ImageModal: FC<ImageModalProps> = ({ image, onClose }) => {
      useEffect(() => {
            document.body.style.overflow = "hidden";
            return () => {
                  document.body.style.overflow = "scroll"
            };
      }, [])
      
      return (
            <div className={classes.modalOverlay} onClick={onClose}>
                  <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                        <div className={classes.modalImage__body}>
                              <img className={classes.modalImage} src={image} alt="" />
                              <button className={classes.closeButton} onClick={onClose}>×</button>
                        </div>
                  </div>
            </div>
      );
};
    
export default ImageModal;
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { INews } from "../../models/INews";
import { STATIC_URL } from "../../http";
import { formatDate } from "../../utilits/formatDate";
import { Context } from "../../index";
import { NOTHING_FOUND_ROUTE } from "../../utilits/consts";
import { observer } from "mobx-react-lite";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import ComeBackButton from "../../UI/buttons/ComeBackButton";
import Skeleton from "../../UI/skeletons/Skeleton";

import { ReactComponent as Clock } from '../../assets/icons/clock.svg';

import classes from './NewsItem.module.scss';
import { Helmet } from "react-helmet";

const NewsPage= () => {
    const { id } = useParams<{ id: string }>();
    const { newsStore } = useContext(Context);
    const { isLoading } = newsStore;
    const [news, setNews] = useState<INews | null>(null);
    const [descriptionHtml, setDescriptionHtml] = useState<string>("");
    const navigate = useNavigate()

    useEffect(() => {
        if (!id) {
            navigate(NOTHING_FOUND_ROUTE)
            return;
        }

        const fetchNews = async () => {
            const foundNews = await newsStore.fetchOneNews(parseInt(id));
            if (foundNews.content) {
                try {
                    const contentState = convertFromRaw(JSON.parse(foundNews.content));
                    const editorState = EditorState.createWithContent(contentState);
                    const html = stateToHTML(editorState.getCurrentContent());
                    setDescriptionHtml(html);
                } catch (error) {
                    setDescriptionHtml(foundNews.content);
                }
            }
            if(!foundNews) {
                navigate(NOTHING_FOUND_ROUTE)
            }
            setNews(foundNews)
        };
        fetchNews();
    }, [id, newsStore])
    
    if (isLoading) {
        return (
            <div className={classes.news__container}>
                <div className={classes.news__content}>
                    <ComeBackButton/>
                    <div className={classes.news__card}>
                        <div className={classes.card__info}>
                            <Skeleton className={classes.news__image}/>
                            <h1><Skeleton width={"70%"} height={35} borderRadius={4}/></h1>
                            <span className={classes.news__creationTime}>
                                <Skeleton width={150} height={25} borderRadius={4}/>
                            </span>
                            <p>
                                <Skeleton width={"100%"} height={100} borderRadius={4}/>
                            </p>
                        </div>
                        <Skeleton width={"100%"} height={150} borderRadius={12}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
           <Helmet>
                <title>{news?.title || "Новость"}</title>
                <meta
                    name="description"
                    content={`Подробности: ${news?.title || "новости"} - узнайте больше.`}
                />
            </Helmet>
            <div className={classes.news__container}>
                <div className={classes.news__content}>
                    <ComeBackButton/>
                    <div className={classes.news__card}>
                        <div className={classes.card__info}>
                            <img src={`${STATIC_URL}/news/${news?.img}`} className={classes.news__image} alt="" />
                            <h1>{news?.title}</h1>
                            <span className={classes.news__creationTime}>
                                <Clock/>
                                {formatDate(news?.createdAt!)}
                            </span>
                            {news?.details && (
                                <p dangerouslySetInnerHTML={{ __html: news.details }}></p>
                            )}
                        </div>
                        {news?.content && (
                            <p dangerouslySetInnerHTML={{ __html: descriptionHtml }} className={classes.news__additionalInfo}></p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(NewsPage);

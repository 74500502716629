import { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { adminRoutes, authRoutes, publicRoutes } from "./routes";
import { HOME_ROUTE, REGISTRATION_ROUTE } from "./utilits/consts";
import { observer } from "mobx-react-lite";
import { Context } from "./index";

import './styles/normalize.css';
import classes from "./styles/App.module.scss";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import QuestionnaireNotification from "./UI/notifications/QuestionnaireNotification";
import useSavePath from "./hooks/useSavePath";
import LoadingDots from "./UI/loadingDots";
import Auth from "./pages/AuthPage/Auth";


const AppRouter = () => {
    const { userStore } = useContext(Context);
    const [loadingAuthRoutes, setLoadingAuthRoutes] = useState(true);
    const navigate = useNavigate();

    useSavePath();

    useEffect(() => {
        const loadUserData = async () => {
            await userStore.checkAuth();

            const redirectPath = localStorage.getItem('redirectPath');
            if (userStore.isAuth && redirectPath) {
                localStorage.removeItem('redirectPath');
                navigate(redirectPath, { replace: true });
            }
            setLoadingAuthRoutes(false); 
        };
        loadUserData();
    }, [userStore]);

    return (
        <div className={classes.francheese__app}>
            <Header />
            <QuestionnaireNotification />
            <Routes>
                <Route path="/recovery-password" element={<Auth />} />
                <Route path="/" element={<Navigate to={HOME_ROUTE} replace />} />
                {publicRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                ))}
                {loadingAuthRoutes ? (
                    <Route path="*" element={<LoadingDots />} />
                ) : (
                    <>
                        {userStore.isAuth && authRoutes.map(({ path, Component }) => (
                            <Route key={path} path={path} element={<Component />} />
                        ))}
                        {userStore.isAdmin && adminRoutes.map(({ path, Component }) => (
                            <Route key={path} path={path} element={<Component />} />
                        ))}
                        <Route path="*" element={<Navigate to={REGISTRATION_ROUTE} replace />} />
                    </>
                )}
            </Routes>
            <Footer />
        </div>
    );
};

export default observer(AppRouter);

import { useState, useContext, useEffect, useMemo } from 'react';
import { IRelease } from '../../models/IRelease';
import { IFranchise } from '../../models/IFranchise';
import { Context } from '../../index';
import { useNotification } from '../../hooks/useNotification';

import CustomButton from '../../UI/buttons/CustomButton';
import InputField from '../../UI/inputs/InputField/InputField';

import classes from './AndminPageForms.module.scss';
import FranchiseSelection from '../../UI/selectors/FranchiseSelection';

interface IReleaseForm {
    franchiseIdToAdd: number,
    franchiseIdToDelete: number,
    specialFranchiseId: number,
    interactiveCardIdToAdd: number,
    interactiveCardIdToDelete: number,
    franchise: undefined | IFranchise,
}

const ReleaseCreator = () => {
    const { releaseStore, franchiseStore } = useContext(Context);
    const { notify, showLoading, hideLoading } = useNotification();
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [formData, setFormData] = useState<IReleaseForm>({
        franchiseIdToAdd: 0,
        franchiseIdToDelete: 0,
        specialFranchiseId: 0,
        interactiveCardIdToAdd: 0,
        interactiveCardIdToDelete: 0,
        franchise: undefined,
    });
    

    // Получение всех релизов
    useEffect(() => {
        const fetchReleases = async () => {
            try {
                await releaseStore.fetchReleases();
            } catch (error) {
                console.error("Неудалось получить релизы:", error);
            }
        };
        fetchReleases();
    }, [releaseStore.releases]);

    // Получение франшиз которые не являются релизами
    const availableFranchises = useMemo(() => {
        return franchiseStore.franchises.filter(franchise => 
            !releaseStore._releases.some(release => release.franchiseId === franchise.id)
        );
    }, [releaseStore.releases]);

    // Получение вех франшиз из списка инетрактивных
    const [allInteractiveCards, setAllInteractiveCards] = useState<IFranchise[]>([]);
    const fetchInteractiveCards = async () => {
        try {
            await releaseStore.fetchAllInteractiveCards();
            setAllInteractiveCards(releaseStore._interactiveCards ?? []); 
        } catch (error) {
            console.error("Не удалось получить интерактивные карты:", error);
        }
    };
    
    useEffect(() => {
        fetchInteractiveCards();
    }, [releaseStore]);

    useEffect(() => {
        const fetchAllFranshises = async () => {
            try {
                await franchiseStore.fetchFranchises(1, 1, true);
            } catch (error) {
                console.error("Не удалось получить все франшизы", error);
            }
        };
        fetchAllFranshises();
    }, []);

    const nonInteractiveReleases = useMemo(() => {
        return releaseStore.releases.filter(franchise => {
            return !releaseStore._interactiveCards.some(interactiveCard => interactiveCard.id === franchise.franchiseId);
        });
    }, [releaseStore.releases, releaseStore._interactiveCards]);
    
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        field: keyof IReleaseForm
    ) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value,
        });

        setErrors({ ...errors, [field]: false });
    };

    const handleAddRelease = async () => {
        try {
            if (formData.franchiseIdToAdd === 0) {
                setErrors({ ...errors, franchiseId: true });
                return;
            }

            showLoading("Создаем релиз");
            await releaseStore.addNewRelease(formData.franchiseIdToAdd);
            hideLoading();
            notify(`Новый релиз успешно создан!`);

            clearForm();
        } catch (error) {
            notify(`Не удалось добавить релиз: ${error}`);
        }
    };

    const handleDeleteRelease = async () => {
        try {
            if (formData.franchiseIdToDelete === 0) {
                setErrors({ ...errors, id: true });
                return;
            }
            showLoading("Удаляем франшизу")
            await releaseStore.removeRelease(formData.franchiseIdToDelete);
            hideLoading();
            await fetchInteractiveCards();
            notify("Релиз успешно удалён!");
            clearForm();
        } catch (error) {
            notify(`Не удалось удалить релиз: ${error}`);
            console.error("Не удалось удалить релиз:", error);
        }
    };

    const handleSetFeaturedFranchise = async () => {
        try {
            const selectedFranchiseId = formData.specialFranchiseId;
            if (selectedFranchiseId === 0) {
                setErrors({ ...errors, franchiseId: true });
                return;
            }

            showLoading("Устанавливаем осоую франшизу")
            await releaseStore.setSpecialFranchise(selectedFranchiseId);
            await releaseStore.fetchSpecialFranchise();
            hideLoading();
            notify("Специальная франшиза успешно установлена!");
        } catch (error) {
            notify(`Не удалось специальную франшизу: ${error}`);
            console.error("Не удалось установить специальную франшизу:", error);
        }
    };

    const handleAddInteractiveCard = async () => {
        try {
            if (formData.interactiveCardIdToAdd === 0) {
                setErrors({ ...errors, interactiveCardIdToAdd: true });
                return;
            }
            showLoading("Добавляем интерактивную карту");
            await releaseStore.addInteractiveCard(formData.interactiveCardIdToAdd);
            await fetchInteractiveCards();
            hideLoading();

            notify("Интерактивная карта успешно добавлена!");
        } catch (error) {
            notify(`Не удалось добавить интерактивную карту: ${error}`);
            console.error("Ошибка при добавлении интерактивной карты:", error);
        }
    };

    const handleRemoveInteractiveCard = async () => {
        try {
            if (formData.interactiveCardIdToDelete === 0) {
                setErrors({ ...errors, interactiveCardIdToDelete: true });
                return;
            }

            showLoading("Удаляем интерактивную карту");
            await releaseStore.removeInteractiveCard(formData.interactiveCardIdToDelete);
            await fetchInteractiveCards();
            hideLoading();
            notify("Интерактивная карта успешно удалена!");
        } catch (error) {
            notify(`Не удалось удалить интерактивную карту: ${error}`);
            console.error("Ошибка при удалении интерактивной карты:", error);
        }
    };

    const [selectedFranchises, setSelectedFranchises] = useState<number[]>([]);

    const handleSetRecommendedFranchises = async () => {
        if (selectedFranchises.length !== 6) {
            notify('Необходимо выбрать 6 франшиз');
            return;
        }

        showLoading("Устанавливаем новые рекомендации");
        await franchiseStore.setRecommendedFranchises(selectedFranchises);
        await franchiseStore.fetchRecommendedFranchises();
        hideLoading();
        notify('Рекомендованные франшизы успешно установлены!');
    };

    const handleFranchisesChange = (newSelectedFranchises: number[]) => {
        setSelectedFranchises(newSelectedFranchises);
    };

    const clearForm = () => {
        setFormData({ franchiseIdToAdd: 0, franchiseIdToDelete: 0, specialFranchiseId: 0,
                      interactiveCardIdToAdd: 0, interactiveCardIdToDelete: 0, franchise: undefined });
        setErrors({});
    };
    
    return (
        <div className={classes.adminDashboard__container}>
            <div className={classes.adminDashboard__content}>
                {/* Секция для добавления релизов */}
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Добавить релиз</h1>
                        <div className={classes.adminToolBox__controllers}>
                        <InputField
                            as="select"
                            value={formData.franchiseIdToAdd || ''}
                            onChange={(e) => handleInputChange(e, 'franchiseIdToAdd')}
                            error={errors.franchiseIdToAdd}
                            errorMessage="Это поле обязательно!"
                        >
                            <option value="">Выберите франшизу для добавления</option>
                            {availableFranchises.map((franchise: IFranchise) => (
                                <option key={franchise.id} value={franchise.id}>
                                    {franchise.name}
                                </option>
                            ))}
                        </InputField>

                        </div>
                        <CustomButton onClick={handleAddRelease}>Добавить релиз</CustomButton>
                    </div>
                </div>
                
                {/* Секция для удаления релизов */}
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Удалить релиз</h1>
                        <div className={classes.adminToolBox__controllers}>
                            <InputField
                                as="select"
                                value={formData.franchiseIdToDelete || ''}
                                onChange={(e) => handleInputChange(e, 'franchiseIdToDelete')}
                                error={errors.franchiseIdToDelete}
                                errorMessage="Это поле обязательно!"
                            >
                                <option value="">Выберите релиз для удаления</option>
                                {releaseStore._releases.map((release: IRelease) => (
                                    <option key={release.id} value={release.id}>
                                        {release.franchise?.name}
                                    </option>
                                ))}
                            </InputField>
                        </div>
                        <CustomButton onClick={handleDeleteRelease}>Удалить релиз</CustomButton>
                    </div>
                </div>
                
                {/* Секция для выбора специальной карты */}
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Установить специальную франшизу</h1>
                        <div className={classes.adminToolBox__controllers}>
                            <InputField
                                as="select"
                                value={formData.specialFranchiseId || ''}
                                onChange={(e) => handleInputChange(e, 'specialFranchiseId')}
                                error={errors.specialFranchiseId}
                                errorMessage="Это поле обязательно!"
                            >
                                <option value="">Выберите франшизу для установки в качестве специальной</option>
                                {releaseStore.releases.map((release: IRelease) => (
                                    <option key={release.id} value={release.id}>
                                        {release.franchise?.name}
                                    </option>
                                ))}
                            </InputField>
                        </div>
                        <CustomButton onClick={handleSetFeaturedFranchise}>
                            Установить специальную франшизу
                        </CustomButton>
                    </div>
                </div>

                 {/* Секция для добавления интерактивной карты */}
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Добавить интерактивную карту</h1>
                            <div className={classes.adminToolBox__controllers}>
                            <InputField
                                as="select"
                                value={formData.interactiveCardIdToAdd || ''}
                                onChange={(e) => handleInputChange(e, 'interactiveCardIdToAdd')}
                                error={errors.interactiveCardIdToAdd}
                                errorMessage="Это поле обязательно!"
                            >
                                <option value="">Выберите франшизу для добавления</option>
                                {nonInteractiveReleases.map((franchise) => (
                                    <option key={franchise.id} value={franchise.id}>
                                        {franchise.franchise?.name}
                                    </option>
                                ))}
                            </InputField>
                        </div>
                        <CustomButton onClick={handleAddInteractiveCard}>Добавить интерактивную карту</CustomButton>
                    </div>
                </div>
                
                {/* Секция для удаления интерактивной карты */}
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Удалить интерактивную карту</h1>
                        <div className={classes.adminToolBox__controllers}>
                            <InputField
                                as="select"
                                value={formData.interactiveCardIdToDelete || ''}
                                onChange={(e) => handleInputChange(e, 'interactiveCardIdToDelete')}
                                error={errors.interactiveCardIdToDelete}
                                errorMessage="Это поле обязательно!"
                            >
                                <option value="">Выберите карту для удаления</option>
                                {allInteractiveCards && allInteractiveCards.map((franchise) => (
                                    <option key={franchise.id} value={franchise.id}>
                                        {franchise.name}
                                    </option>
                                ))}
                            </InputField>
                        </div>
                        <CustomButton onClick={handleRemoveInteractiveCard}>Удалить интерактивную карту</CustomButton>
                    </div>
                </div>

                {/* Секция для выбора ркомендованных франшиз */}
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Выбрать рекомендованные франшизы</h1>
                        <div className={classes.adminToolBox__controllers}>
                            <FranchiseSelection onFranchisesChange={handleFranchisesChange} />
                        </div>
                        <CustomButton onClick={handleSetRecommendedFranchises}>
                            Установить рекомендованные франшизы
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReleaseCreator;

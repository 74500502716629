import { FC, useEffect, useState } from 'react';

import classes from './PhoneInput.module.scss';
import classNames from 'classnames';

import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ExclamationPoint from './../../assets/icons/exclamation_point.svg';

interface PhoneInputProps {
    value: string;
    onChange: (phone: string) => void;
    error?: boolean;
    country?: string;
}

const PhoneInput: FC<PhoneInputProps> = ({ value, onChange, error = false, country = 'by' }) => {
    const [hasError, setHasError] = useState(error);

      useEffect(() => {
            setHasError(error); 
      }, [error]);

      const handlePhoneChange = (phone: string) => {
            onChange(phone); 
            if (hasError) {
                setHasError(false); 
            }
      };

    return (
        <div className={classes.phoneInputWrapper}>
            <PhoneInputComponent
                country={country}
                value={value}
                onChange={handlePhoneChange}
                inputClass={classNames(classes.phoneInput, { [classes.error]: hasError })}
                containerClass={classes.phoneInputContainer}
                dropdownClass={classes.phoneDropDown}
                buttonClass={classNames(classes.phoneInputButton, { [classes.error]: hasError })}
            />
            {hasError && <img className={classes.errorIcon} src={ExclamationPoint} alt="Ошибка" />}
        </div>
    );
};

export default PhoneInput;

import { FC } from "react";
import classes from '../../pages/NewsPage/NewsObject/NewsObject.module.scss';
import CustomButton from "../buttons/CustomButton";
import Skeleton from "./Skeleton";

interface SkeletonNewsObjectProps {
     index: number;
}
const SkeletonNewsObject: FC<SkeletonNewsObjectProps> = ({ index }) => {
     return (
          <div className={`${classes.newsItem__container} ${index % 2 === 0 ? classes.even : classes.odd}`}>
               <Skeleton className={classes.news__image}/>
               <div className={classes.content__box}>
                    <Skeleton width={"40%"} height={"2rem"} borderRadius={4}/>
                    <Skeleton width={"15%"} height={"1.3rem"} borderRadius={4}/>
                    <Skeleton width={"80%"} height={"3.5rem"} borderRadius={4}/>
                    <div className={classes.read__button}>
                         <CustomButton disabled loading>
                              Читать
                         </CustomButton>
                    </div>
               </div>
          </div>
     );
};

export default SkeletonNewsObject;
import { useCallback, useContext, useEffect, useState } from "react";
import classes from './ProfileCard.module.scss';
import CustomButton from "../../../UI/buttons/CustomButton";
import ProfileInput from "../../../UI/inputs/ProfileInput";
import FileUploader from "../../../UI/fileUploaders/FileUploader";
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg';
import { Context } from "../../../index";
import { STATIC_URL } from "../../../http";
import DefaultImage from '../../../assets/defaultUser.png'
import { observer } from "mobx-react-lite";

interface FormData {
     email: string;
     login: string;
     firstName: string;
     lastName: string;
     userImage: string;
}

interface FieldErrors {
     email: boolean;
     login: boolean;
     firstName: boolean;
     lastName: boolean;
     userImage: boolean;
}

const initialFieldErrors: FieldErrors = {
     email: false,
     login: false,
     firstName: false,
     lastName: false,
     userImage: false,
};

const ProfileCard = () => {
     const { userStore, userFranchiseStore } = useContext(Context);
     const [isEditing, setIsEditing] = useState(false);
     const bodyType = isEditing ? 'filled' : 'empty';
     
     const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
     
     const [userData, setUserData] = useState({
          firstName: userStore.user.firstName,
          lastName: userStore.user.lastName,
          login: userStore.user.login,
          email: userStore.user.email,
          userImage: userStore.user.img ? `${STATIC_URL}/users/${userStore.user.img}` : DefaultImage,
     });
     const [fieldErrors, setFieldErrors] = useState<FieldErrors>(initialFieldErrors);
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [serverError, setServerError] = useState<string | null>(null);
     const [profileImage, setProfileImage] = useState<File | null>(null);
 
     useEffect(() => {
          const loadUserData = async () => {
               await userFranchiseStore.loadUserFranchises();
          };
          loadUserData();
     }, [userFranchiseStore, userStore]);
 
     const toggleEditing = () => {
          if (isEditing) {
               setUserData({
                    firstName: userStore.user.firstName,
                    lastName: userStore.user.lastName,
                    login: userStore.user.login,
                    email: userStore.user.email,
                    userImage: userStore.user.img ? `${STATIC_URL}/users/${userStore.user.img}` : DefaultImage,
               });
              setFieldErrors(initialFieldErrors);
          }
          setIsEditing(prev => !prev);
     };

     const LogoutHandle = () => {
          userStore.logout();
     };
 
     //#region Handlers
     const handleReset = () => {
          setUserData({
              firstName: userStore.user.firstName,
              lastName: userStore.user.lastName,
              login: userStore.user.login,
              email: userStore.user.email,
              userImage: `${STATIC_URL}/users/${userStore.user.img}`,
          });
          setFieldErrors(initialFieldErrors);
          setProfileImage(null);
          setIsEditing(false);
     };
      
     const handleSave = async () => {
          setIsSubmitClicked(true);
          setIsLoading(true);
          setServerError(null);
      
          const isFormValid = validateForm();
      
          if (!isFormValid) {
              setIsLoading(false);
              return;
          }
      
          try {
               const formData = new FormData();
               formData.append('firstName', userData.firstName);
               formData.append('lastName', userData.lastName);
               formData.append('login', userData.login);
               formData.append('email', userData.email);
               if (profileImage) {
                    formData.append('userImage', profileImage);
               }
      
               await userStore.updateUser(userData.email, userData.firstName, userData.lastName, userData.login, profileImage || new File([], ""));
               setIsEditing(false);
          } catch (error: any) {
               setServerError(error.message);
               if(error.message) {
                    if (error.message.includes('Данный логин уже занят')) {
                         setFieldErrors(prevErrors => ({
                              ...prevErrors,
                              login: true
                         }));
                    }
                    if (error.message.includes('Данная почта уже занята')) {
                         setFieldErrors(prevErrors => ({
                              ...prevErrors,
                              email: true
                         }));
                    }
               }
          } finally {
              setIsLoading(false);
          }
     };
      
     const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof FormData) => {
          const { value } = e.target;
          setUserData(prevState => {
               const newState = { ...prevState, [field]: value };
               if (isSubmitClicked) {
                    validateField(field, value);
               }
               return newState;
          });
     };

     const handleImageChange = (image: File) => {
          setProfileImage(image);
          setUserData(prev => ({ ...prev, userImage: URL.createObjectURL(image) }));
     };
     //#endregion
     
     //#region Validation
     const validateField = useCallback((name: keyof FormData, value: string) => {
          let isValid = true;
      
          switch (name) {
               case 'login':
                    isValid = /^[a-zA-Z0-9]{4,}$/.test(value);
                    break;
               case 'email':
                    isValid = /^[a-z0-9.%_+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value);
                    break;
               case 'firstName':
               case 'lastName':
                    isValid = value.trim() !== '';
                    break;
               default:
                    isValid = true;
          }
      
          setFieldErrors(prevErrors => ({
               ...prevErrors,
               [name]: !isValid
          }));
      
          return isValid;
     }, []);
      
     const validateForm = () => {
          const isFirstNameValid = validateField('firstName', userData.firstName);
          const isLastNameValid = validateField('lastName', userData.lastName);
          const isLoginValid = validateField('login', userData.login);
          const isEmailValid = validateField('email', userData.email);
          
          return isFirstNameValid && isLastNameValid && isLoginValid && isEmailValid;
     };
 
     //#endregion
     
     return (
          <div className={classes.profile__card}>
               <h2>Личные данные</h2> 
               <div className={classes.leave__button} onClick={LogoutHandle}>
                    <Logout />
               </div>

               <div className={classes.profile__box}>
                    <FileUploader
                         onImageChange={handleImageChange}
                         defaultImageURL={userData.userImage}
                         width="clamp(100px, 80px + 10vw, 180px)"
                         height="clamp(100px, 80px + 10vw, 180px)"
                         borderRadius="100%"
                         disabled={!isEditing}
                         isUserImageUploader
                    />
                    <div className={classes.user__info}>
                         <h3>{userStore.user.firstName} {userStore.user.lastName}</h3>
                         <span>{userStore.user.email}</span>
                    </div>
               </div>

               <div className={classes.userCard_gridBox}>
                    <ProfileInput
                         userData={userData.firstName}
                         value={userData.firstName}
                         fieldError={fieldErrors.firstName}
                         onChange={(e) => handleInputChange(e, 'firstName')}
                         isEditing={isEditing}
                         disabled={!isEditing}
                         errorMessage="Это обязательное поле. Заполните его!"
                         isSubmitClicked={isSubmitClicked}
                    />
                    <ProfileInput
                         userData={userData.login}
                         value={userData.login}
                         fieldError={fieldErrors.login}
                         onChange={(e) => handleInputChange(e, 'login')}
                         isEditing={isEditing}
                         hintMessage="Как должен выглядеть логин:"
                         hints={[
                             "Длина логина: от 4 до 20 символов",
                             "Буквы латинского алфавита и цифры",
                             "Не содержит пробелы и специальные символы"
                         ]}
                         errorMessage={serverError?.includes('Данный логин уже занят') ? serverError : "Неверный формат логина"} 
                         disabled={!isEditing}
                         isSubmitClicked={isSubmitClicked}
                    />
                    <ProfileInput
                         userData={userData.lastName}
                         value={userData.lastName}
                         fieldError={fieldErrors.lastName}
                         onChange={(e) => handleInputChange(e, 'lastName')}
                         errorMessage="Это обязательное поле. Заполните его!"
                         isEditing={isEditing}
                         disabled={!isEditing}
                         isSubmitClicked={isSubmitClicked}
                    />
                    <ProfileInput
                         userData={userData.email}
                         value={userData.email}
                         fieldError={fieldErrors.email}
                         onChange={(e) => handleInputChange(e, 'email')}
                         isEditing={isEditing}
                         errorMessage={serverError?.includes('Данная почта уже занята') ? serverError : "Неверный формат почты"} 
                         disabled={!isEditing}
                         isSubmitClicked={isSubmitClicked}
                    />
                    {isEditing && (
                         <CustomButton
                              borderRadius="0.875rem"
                              width="100%"
                              bodyType="empty"
                              fontSize="1rem"
                              onClick={handleReset}
                         >
                              Отменить
                         </CustomButton>
                    )}
                    <CustomButton
                         borderRadius="0.875rem"
                         width="100%"
                         bodyType={bodyType}
                         fontSize="1rem"
                         onClick={isEditing ? handleSave : toggleEditing}
                         loading={isLoading}
                         style={{
                              gridColumn: "2 / 3"
                         }}
                    >
                         {isEditing ? 'Сохранить' : 'Изменить'}
                    </CustomButton>
               </div>
        </div>
    );
};

export default observer(ProfileCard);

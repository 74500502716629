import classes from './../franchiseBoxes/FranchiseBox.module.scss';
import Skeleton from "./Skeleton";
import CustomButton from "../buttons/CustomButton";

const SkeletonFranchiseBox = () => {
     return (
          <div className={classes.franchiseBox__container}>
               <Skeleton className={classes.franchiseImage} borderRadius={20} contrast="dark"/>
               <h2>
                    <Skeleton width={"70%"} height={"2.5rem"} borderRadius={4} contrast="dark"/>
               </h2>
               <div className={classes.franchise__info}>
                    <div className={classes.creationTime__box}>
                         <Skeleton width={"40%"} height={"2.5rem"} borderRadius={4} contrast="dark"/>
                    </div>
                    <Skeleton className={classes.franchise__descripton} width={"100%"} height={"25vw"} borderRadius={4} contrast="dark"/>
               </div>
               <span className={classes.totalViews}>
                    <Skeleton width={"40%"} height={"2.5rem"} borderRadius={4} contrast="dark"/>
               </span>

               <div className={classes.toFranchisePage__button}>
                    <CustomButton
                         padding="1.125rem 1.5rem"
                         loading
                         style={{
                              position: "absolute",
                              right: "-10px",
                              bottom: "-10px",
                              border: "10px solid white",
                         }}
                         >Загрузка
                    </CustomButton>
               </div>
          </div>
     );
};

export default SkeletonFranchiseBox;
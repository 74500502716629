import { FC, ChangeEvent, InputHTMLAttributes, useState } from "react";
import classes from "./ProfileInput.module.scss";
import Hint from "../Hint";

interface ProfileInputProps extends InputHTMLAttributes<HTMLInputElement> {
    userData: string;
    fieldError?: boolean;
    errorMessage?: string;
    isEditing?: boolean;
    isSubmitClicked: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    hintMessage?: string;
    hints?: string[];
}

const ProfileInput: FC<ProfileInputProps> = ({
    userData,
    fieldError,
    errorMessage,
    isEditing,
    onChange,
    isSubmitClicked,
    hintMessage,
    hints,
    ...props
}) => {
    const [showHint, setShowHint] = useState(false);

    const handleFocus = () => {
        if (hintMessage) {
            setShowHint(true);
        }
    };

    const handleBlur = () => {
        setShowHint(false);
    };

    const inputClassName = `${isEditing ? classes.editingInput : classes.readOnlyInput} ${
        (fieldError && isSubmitClicked) || (isEditing && fieldError) ? classes.ErrorInput : ""
    }`;

    return (
        <div className={classes.profileInput__container}>
            <input
                {...props}
                type="text"
                value={userData}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={inputClassName}
                readOnly={!isEditing}
            />
            {showHint && hintMessage && 
                <Hint 
                    message={hintMessage}
                    hints={hints || []}
                    profileHint
                />
            }

            {(fieldError && (isSubmitClicked || isEditing)) && (
                <span className={classes.errorMessage}>{errorMessage}</span>
            )}
        </div>
    );
};

export default ProfileInput;

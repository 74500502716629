import { AxiosResponse } from "axios";
import { IUser } from "../models/IUser";
import $api from "../http/index";

export default class UserService {
     static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
          return $api.get<IUser[]>('/users/get-all-users');
     }

     static fetchUserById(userId: number): Promise<AxiosResponse<IUser>> {
          return $api.get<IUser>(`/users/get-user/${userId}`);
     }

     static async updateUser(
          email: string,
          firstName: string,
          lastName: string,
          login: string,
          img: File,
     ) {
          try {
               const formData = new FormData();
               formData.append('email', email);
               formData.append('firstName', firstName);
               formData.append('lastName', lastName);
               formData.append('login', login);
               formData.append('img', img);

               return await $api.put<IUser>('/users/update-user', formData, {
                    headers: {
                         'Content-Type': 'multipart/form-data',
                    },
               });
          } catch (e: any) {
               throw new Error(e.response?.data?.message);
          }
     }

     static async checkAdmin(): Promise<boolean> {
          const response = await $api.get<{ isAdmin: boolean }>('/users/check-admin', { withCredentials: true });
          return response.data.isAdmin;
     }

     static async checkLogin(login: string): Promise<AxiosResponse<boolean>> {
          try {
               return await $api.post<boolean>('/users/check-login', { login });
          } catch (e: any) {
               throw new Error(e.response?.data?.message || 'Ошибка авторизации');
          }
     }

     static async checkRecoveryLink(recoveryLink: string) {
          try {
               return await $api.post(`users/recovery/${recoveryLink}`);
          } catch (e: any) {
               throw new Error(e.response?.data?.message);
          }
     }

     static async sendFranchiseApprovalStatus(franchiseId: number) {
          try {
               return await $api.post('/send-franchise-approval-status', {franchiseId});
          } catch (e: any) {
               throw new Error(e.response?.data?.message);
          }
     }

     static async checkEmail(email: string): Promise<AxiosResponse<boolean>> {
          try {
               return await $api.post<boolean>('/users/check-email', { email });
          } catch (e: any) {
               throw new Error(e.response?.data?.message);
          }
     }
}
import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import AuthService from "../service/AuthService";
import { AuthResponse } from "../models/response/AuthResponse";
import $api from "../http/index";
import FavoritesStore from "./FavoritesStore";
import UserFranchiseStore from "./UserFranchiseStore";
import UserService from "../service/UserService";

export default class UserStore {
     user = {} as IUser;
     isAuth = false;
     isAdmin = false;
     isLoading = false;
     favoritesStore: FavoritesStore;
     userFranchiseStore: UserFranchiseStore;

     constructor(favoritesStore: FavoritesStore, userFranchiseStore: UserFranchiseStore) {
          makeAutoObservable(this);
          this.favoritesStore = favoritesStore;
          this.userFranchiseStore = userFranchiseStore;
     }

     setAdmin(bool: boolean) {
          this.isAdmin = bool;
     }

     setAuth(bool: boolean) {
          this.isAuth = bool;
     }

     setUser(user: IUser) {
          this.user = user;
     }

     setLoading(bool: boolean) {
          this.isLoading = bool;
     }

     async login(identifier: string, password: string) {
          try {
               const response = await AuthService.login(identifier, password);
               localStorage.setItem('accessToken', response.data.accessToken);
               this.setAuth(true);
               this.setUser(response.data.user);
               await this.favoritesStore.loadFavorites();
               await this.userFranchiseStore.loadUserFranchises();
          } catch (error: any) {
               throw error;
          }
     }

     async checkLogin(login: string) {
          try {
               await AuthService.checkLogin(login);
          } catch (error: any) {
               throw error;
          }
     }

     async checkEmail(email: string) {
          try {
               await AuthService.checkEmail(email);
          } catch (error: any) {
               throw error;
          }
     }

     async registration(email: string, password: string, login: string, firstName: string, lastName: string, activationCode: string, img: File) {
          try {
               const response = await AuthService.registration(email, password, login, firstName, lastName, activationCode, img);
               localStorage.setItem('accessToken', response.data.accessToken);
               this.setUser(response.data.user);
               this.setAuth(true);
          } catch (error: any) {
               throw new Error(error.response?.data?.message || 'Неверный код');
          }
     }

     async logout() {
          try {
               await AuthService.logout();
               this.favoritesStore.clearFavorites();
               localStorage.removeItem('refreshToken');
               this.setAuth(false);
               this.setAdmin(false);
               this.setUser({} as IUser);
          } catch (error) {
               console.error('Logout error:', error);
          }
     }

     async checkAuth() {
          this.setLoading(true);
          const token = localStorage.getItem('accessToken');
      
          if (!token) {
              this.setAuth(false);
              this.setLoading(false);
              return;
          }
      
          try {
              const response = await $api.get<AuthResponse>('/users/refresh', {
                  headers: { Authorization: `Bearer ${token}` }
              });
      
              localStorage.setItem('accessToken', response.data.accessToken);
              this.setAuth(true);
              this.setUser(response.data.user);
      
              await this.favoritesStore.loadFavorites();
              await this.userFranchiseStore.loadUserFranchises();
          } catch (error) {
              console.error('Ошибка авторизации:', error);
              localStorage.removeItem('accessToken');
              this.setAuth(false);
          } finally {
              this.setLoading(false);
          }
     }

     async checkAdmin() {
          try {
               const isAdmin = await UserService.checkAdmin();
               this.setAdmin(isAdmin);
          } catch (error) {
               this.setAdmin(false);
          }
     }

     async fetchUsers() {
          try {
               const response = await UserService.fetchUsers();
               return response.data;
          } catch (error) {
               console.error(error);
               throw error;
          }
     }

     async fetchUserById(userId: number) {
          this.setLoading(true)
          try {
               const response = await UserService.fetchUserById(userId);
               return response.data;
          } catch (error) {
               console.error(error);
               throw error; 
          } finally {
               this.setLoading(false)
          }
     }

     async changePassword(userId: string, password: string) {
          try {
               const response = await AuthService.changePassword(userId, password);
               localStorage.setItem('accessToken', response.data.accessToken);
               this.setUser(response.data.user);
               this.setAuth(true);
          } catch (error: any) {
               throw new Error(error.response?.data?.message);
          }
     }

     async updateUser(email: string, firstName: string, lastName: string, login: string, img: File) {
          try {
               await UserService.updateUser(email, firstName, lastName, login, img);
          } catch (error: any) {
               throw error;
          }
     }

     async checkRecoveryLink(recoveryLink: string) {
          try {
               await UserService.checkRecoveryLink(recoveryLink);
          } catch (error: any) {
               throw error;
          }
     }
}
import { FC, useState, useEffect } from 'react';
import { Editor, EditorState, RichUtils,convertToRaw, DraftHandleValue } from 'draft-js';
import 'draft-js/dist/Draft.css';
import classes from './DraftEditor.module.scss';

interface DraftEditorProps {
    editorState: EditorState;
    onEditorStateChange: (state: EditorState) => void;
    onContentChange?: (content: string) => void;
    error?: boolean;
    errorMessage?: string;
    maxLength: number;
}

const DraftEditor: FC<DraftEditorProps> = ({ editorState, onEditorStateChange, onContentChange, error, errorMessage, maxLength }) => {
    const [charCount, setCharCount] = useState<number>(editorState.getCurrentContent().getPlainText().length);

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const contentAsText = contentState.getPlainText();
        setCharCount(contentAsText.length);
    }, [editorState]);

    const handleKeyCommand = (command: string, state: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(state, command);
        if (newState) {
            onEditorStateChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onEditorChange = (state: EditorState) => {
        const contentState = state.getCurrentContent();
        const contentAsText = contentState.getPlainText();

        if (contentAsText.length <= maxLength) {
            onEditorStateChange(state);
            setCharCount(contentAsText.length);

            if (onContentChange) {
                const contentJSON = JSON.stringify(convertToRaw(contentState));
                onContentChange(contentJSON);
            }
        }
    };

    const toggleInlineStyle = (style: string) => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, style));
    };

    const toggleBlockType = (blockType: string) => {
        onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    return (
        <div className={`${classes.draftEditor} ${error ? classes.error : ''}`}>
            <div className={classes.toolbar}>
                <button onClick={() => toggleInlineStyle('BOLD')}>Жирный</button>
                <button onClick={() => toggleInlineStyle('ITALIC')}>Курсив</button>
                <button onClick={() => toggleInlineStyle('UNDERLINE')}>Подчёркивание</button>
                <button onClick={() => toggleBlockType('unordered-list-item')}>Маркер</button>
                <button onClick={() => toggleBlockType('ordered-list-item')}>Нумерация</button>
            </div>
            <div className={classes.editor}>
                <Editor
                    placeholder='Введите свой текст'
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={onEditorChange}
                />
            </div>
            <div className={classes.charCount}>
                {charCount}/{maxLength}
            </div>
            {error && errorMessage && (
                <div className={classes.errorMessage}>{errorMessage}</div>
            )}
        </div>
    );
};

export default DraftEditor;
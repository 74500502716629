import { FC, useRef, useState, useEffect } from 'react';
import { TextareaProps } from './types';
import classes from './InputField.module.scss';

const TextareaComponent: FC<TextareaProps> = ({ maxLength, ...props }) => {
     const [charCount, setCharCount] = useState(0);
     const textareaRef = useRef<HTMLTextAreaElement | null>(null);

     useEffect(() => {
          if (textareaRef.current) {
               autoResize(textareaRef.current);
               setCharCount(textareaRef.current.value.length);
          }
     }, [props.value]);

     const autoResize = (textarea: HTMLTextAreaElement) => {
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
     };

     const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setCharCount(e.target.value.length);
          if (props.onChange) 
               props.onChange(e);
          };

          return (
          <div className={classes.textareaWrapper}>
               <textarea
                    {...props}
                    maxLength={maxLength}
                    className={classes.input}
                    ref={textareaRef}
                    onChange={handleChange}
               />
               {maxLength && (
                    <div className={classes.charCount}>
                         {charCount}/{maxLength}
                    </div>
               )}
          </div>
     );
};

export default TextareaComponent;

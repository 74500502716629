import { FC } from "react";
import NothingFoundImage from '../../assets/nothing_found.png';
import classes from './NothingFound.module.scss';

interface NothingFoundProps {
    text?: string;
}

const NothingFound: FC<NothingFoundProps> = ({ text = 'Cтраница не найдена' }) => {
    return (
        <div className={classes.nothingFound__container}>
            <div className={classes.nothingFound__content}>
                <img src={NothingFoundImage} alt="Nothing Found" />
                <h1>{text}</h1>
            </div>
        </div>
    );
};

export default NothingFound;

import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { IIndustry } from '../../models/IIndustry';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FRANCHISE_EDIT_ROUTE, NOTHING_FOUND_ROUTE, PROFILE_ROUTE } from '../../utilits/consts';
import { fetchFile } from '../../utilits/fetchFile';
import { STATIC_URL } from '../../http';
import { useNotification } from '../../hooks/useNotification';

import FileUploader from '../../UI/fileUploaders/FileUploader';
import CustomButton from '../../UI/buttons/CustomButton';

import classes from './FranchiseCreator.module.scss';
import InputField from '../../UI/inputs/InputField/InputField';
import DescriptionBlock from './DescriptionBlock/DescriptionBlock';
import { IDescriptionBlock } from '../../models/IDescriptionBlock';
import { convertToRaw, EditorState, ContentState, convertFromRaw  } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import LoadingDots from '../../UI/loadingDots';
import useResponsive from '../../hooks/useResponsive';

interface FormData {
    industryId: string;
    name: string;
    investments: string;
    paybackTime: string;
    typeOfActivityId: string;
    brand: string;
    advertisingFee: string;
    franchisePoints: string;
    ownPoints: string;
    royalty: string;
    companyStartYear: string;
    franchiseStartYear: string;
}

const FranchiseCreator = () => {
    const { franchiseStore, franchiseDescriptionStore, industryStore } = useContext(Context);
    const { notify, showLoading, hideLoading } = useNotification();
    const { isMobile } = useResponsive();
    const [isLoading, setIsLoading] = useState(false);

    const [faceImage, setfaceImage] = useState<File | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [formData, setFormData] = useState<FormData>({
        industryId: '',
        name: '',
        investments: '',
        paybackTime: '',
        typeOfActivityId: '',
        brand: '',
        advertisingFee: '',
        franchisePoints: '',
        ownPoints: '',
        royalty: '',
        companyStartYear:'',
        franchiseStartYear:''
    });
        
    const navigate = useNavigate(); 
    const location = useLocation();
    const isEditMode = location.pathname.startsWith(FRANCHISE_EDIT_ROUTE) && location.pathname.length > FRANCHISE_EDIT_ROUTE.length;
    const { id } = useParams<{ id: string }>(); 

    const [descriptionBlocks, setDescriptionBlocks] = useState<IDescriptionBlock[]>([{
        id: Date.now(),
        description: '',
        imageGallery: [],
        franchiseDescriptionId: null
    }]);
    const [editorStates, setEditorStates] = useState<EditorState[]>(descriptionBlocks.map(() => EditorState.createEmpty()));
    const [galleryFiles, setGalleryFiles] = useState<File[][]>(descriptionBlocks.map(() => []));
    const [blocksData, setBlocksData] = useState(() => 
        descriptionBlocks.map((_, index) => ({
            html: stateToHTML(editorStates[index].getCurrentContent()), 
        }))
    );
    
    //#region DescriptionBlock Handlers
    const handleAddBlock = useCallback(() => {
        if (descriptionBlocks.length < 7) {
            const newBlock = { id: Date.now(), description: '', imageGallery: [], franchiseDescriptionId: null };
            setDescriptionBlocks((prevBlocks) => [...prevBlocks, newBlock]);
            setEditorStates((prevStates) => [...prevStates, EditorState.createEmpty()]);
            setGalleryFiles((prevFiles) => [...prevFiles, []]);
    
            setBlocksData((prevBlocksData) => [
                ...prevBlocksData,
                { html: '' }
            ]);
        }
    }, [descriptionBlocks.length]);
    
    const handleRemoveBlock = useCallback((index: number) => {
        setDescriptionBlocks((prevBlocks) => prevBlocks.filter((_, i) => i !== index));
        setEditorStates((prevStates) => prevStates.filter((_, i) => i !== index));
        setGalleryFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    
        setBlocksData((prevBlocksData) => prevBlocksData.filter((_, i) => i !== index));
    }, []);
    
    
    const handleEditorStateChange = useCallback((index: number, newEditorState: EditorState) => {
        setEditorStates((prevStates) => {
            const updatedEditorStates = [...prevStates];
            updatedEditorStates[index] = newEditorState;
            return updatedEditorStates;
        });
    }, []);
    
    const handleImageChange = useCallback((index: number, galleryImages: File[]) => {
        setGalleryFiles((prevFiles) => {
            const updatedGalleryFiles = [...prevFiles];
            updatedGalleryFiles[index] = [...galleryImages];
            return updatedGalleryFiles;
        });
    }, []);
    
    //#endregion
    
    useEffect(() => {
        if (id && isEditMode) {
            const fetchFranchise = async () => {
                try {
                    setIsLoading(true);
                    showLoading("Загружаем франшизу");
                    const franchise = await franchiseStore.fetchOneFranchise(parseInt(id));
                    setFormData({
                        industryId: franchise.industryId.toString(),
                        name: franchise.name,
                        investments: franchise.investments.toString(),
                        paybackTime: franchise.paybackTime.toString(),
                        typeOfActivityId: franchise.typeOfActivityId.toString(),
                        brand: franchise.brand,
                        advertisingFee: franchise.advertisingFee.toString(),
                        franchisePoints: franchise.franchisePoints.toString(),
                        ownPoints: franchise.ownPoints.toString(),
                        royalty: franchise.royalty.toString(),
                        companyStartYear:franchise.companyStartYear?.toString(),
                        franchiseStartYear: franchise.franchiseStartYear?.toString(),

                    });

                    const faceImageUrl = `${STATIC_URL}/franchises/${franchise.faceImage}`;
                    const faceImageFile = await fetchFile(faceImageUrl, franchise.faceImage);
                    setfaceImage(faceImageFile);
                    
                    const foundDescriptionBlocks = await franchiseDescriptionStore.fetchFranchiseDescriptions(franchise.id);
                    setDescriptionBlocks(foundDescriptionBlocks);

                    const galleryFilesPromises = foundDescriptionBlocks.map(async (block, index) => {
                        if (!block.imageGallery) return []; 
                    
                        const blockGalleryFiles = await Promise.all(
                            block.imageGallery.map(async (name, i) => {
                                try {
                                    const galleryImageUrl = `${STATIC_URL}/franchises/${name}`; 
                                    return await fetchFile(galleryImageUrl, name as string);
                                } catch (error) {
                                    console.error(`Failed to fetch file from ${name}:`, error);
                                    return null;
                                }
                            })
                        );
                        return blockGalleryFiles.filter((file): file is File => file !== null); 
                    });
                    const galleryFiles = await Promise.all(galleryFilesPromises);
                    setGalleryFiles(galleryFiles);

                    // Создание состояний редакторов для каждого блока
                    const updatedEditorStates = foundDescriptionBlocks.map((block) => {
                        try {
                            const contentState = block.description
                                ? convertFromRaw(JSON.parse(block.description))
                                : ContentState.createFromText('');
                            return EditorState.createWithContent(contentState);
                        } catch (error) {
                            console.error("Ошибка парсинга JSON в description:", block.description, error);
                            return EditorState.createEmpty();
                        }
                    });
                    setEditorStates(updatedEditorStates);
                    // Обновление данных блоков после загрузки
                    const updatedBlocksData = foundDescriptionBlocks.map((block, index) => ({
                        html: stateToHTML(updatedEditorStates[index].getCurrentContent()), 
                        plainText: updatedEditorStates[index].getCurrentContent().getPlainText()
                    }));

                    setBlocksData(updatedBlocksData);
                    hideLoading();
                    setIsLoading(false); 
                } catch (error) {
                    console.error("Не удалось получить франшизу", error);
                    navigate(NOTHING_FOUND_ROUTE)
                    setIsLoading(false); 
                }
            };
            fetchFranchise();
        }
    }, [id]);  

    const validateForm = (): boolean => {
        const newErrors: { [key: string]: boolean } = {};
      
        Object.keys(formData).forEach((key) => {
            if (!formData[key as keyof FormData]) {
                newErrors[key] = true;
            }
        });
      
        const requiredFields = {
            faceImage: faceImage,
        };
      
        Object.entries(requiredFields).forEach(([field, isValid]) => {
            if (!isValid) {
                newErrors[field] = true;
            }
        });
      
        descriptionBlocks.forEach((_, index) => {
            const contentState = editorStates[index].getCurrentContent();
            if (!contentState.hasText()) {
                newErrors[`descriptionBlock-${index}`] = true;
            }
      
            if (galleryFiles[index]?.length === 0 && index === 0) {
                newErrors[`galleryFiles-${index}`] = true;
            }
        });
      
        setErrors(newErrors);
        console.log(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    const handleSubmit = async () => {
        if (!validateForm()) {
            notify("Проверьте правильность заполнения формы!");
            return;
        }

        const allDescriptionBlocks = descriptionBlocks.map((block, index) => {
            const contentState = editorStates[index].getCurrentContent();
            block.description = JSON.stringify(convertToRaw(contentState));
            block.imageGallery = galleryFiles[index];
            return block;
        });

        try {
            const franchiseParams = {
                name: formData.name,
                royalty: formData.royalty,
                advertisingFee: formData.advertisingFee,
                investments: formData.investments,
                ownPoints: formData.ownPoints,
                franchisePoints: formData.franchisePoints,
                paybackTime: formData.paybackTime,
                industryId: formData.industryId,
                typeOfActivityId: formData.typeOfActivityId,
                brand: formData.brand,
                faceImage: faceImage!,
                companyStartYear:formData.companyStartYear,
                franchiseStartYear:formData.franchiseStartYear,
                descriptionBlocks: allDescriptionBlocks, 
            };
            setIsLoading(true); 
            showLoading(isEditMode ? "Сохраняем изменения" : "Создаем франшизу");
            if (isEditMode) {
                await franchiseStore.updateFranchise(id!, franchiseParams);
            } else {
                await franchiseStore.createFranchise(franchiseParams);
            }
            hideLoading();
            notify(isEditMode ? "Франшиза успешно обновлена!" : "Франшиза отправлена на рассмотрение!");
            setIsLoading(false);
            navigate(PROFILE_ROUTE);
        } catch (error) {
            console.error("Не удалось создать/изменить франшизу:", error);
            setIsLoading(false);
        }
    };

    //#region franchise handlers
    const handleFaceImageChange = (newImage: File) => {
        setfaceImage(newImage);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, field: keyof FormData) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value
        });
    
        setErrors({ ...errors, [field]: false });
    }; 

    const handleDelete = async () => {
        try {
            if (!id) {
                setErrors({ ...errors, id: true });
                return;
            }
            showLoading("Удаляем франшизу")
            await franchiseStore.deleteOneFranchiseById(parseInt(id));
            hideLoading();
            notify("Франшиза успешно удалена!");
            navigate(PROFILE_ROUTE);
        } catch (error) {
            console.error("Не удалось удалить франшизу:", error);
        }
    };

    const handleIndustryChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const industryId = Number(e.target.value);
        setFormData({
            ...formData,
            industryId: e.target.value,
            typeOfActivityId: ''
        });
    
        if (industryId) {
            await industryStore.fetchSubIndustries(industryId);
        }
    };
    //#endregion
    
    if(isLoading) {
        return (
            <LoadingDots/>
        )
    } 

    return (
        <>
            <div className={classes.franchiseCreator__container}>
                <div className={classes.franchiseCreator__content}>
                    <div className={classes.franchiseCreator__header}>
                        <h1>{isEditMode ? "Режим редактирования" : "Создание франшизы"}</h1>
                        {isEditMode && !isMobile &&
                            <CustomButton 
                                onClick={handleDelete}
                                bodyType="empty"
                            >
                                Удалить франшизу
                            </CustomButton>
                        }
                    </div>

                    <div className={classes.franchiseCreator__body}>
                        <div className={classes.franchise__faceImage}>
                            <FileUploader
                                onImageChange={handleFaceImageChange}
                                error={errors['faceImage']}
                                errorMessage="Это обязательное поле. Заполните его!"
                                defaultImageURL={faceImage ? URL.createObjectURL(faceImage) : undefined}
                            />
                        </div>
                        <div className={classes.franchise__column}>
                            <InputField
                                as='select'
                                value={formData.industryId || ''}
                                onChange={handleIndustryChange}
                                error={errors.industryId}
                                errorMessage="Это обязательное поле. Заполните его!"
                            >
                                <option value="">Выберите категорию</option>
                                {industryStore.industries.map((industry: IIndustry) => (
                                    <option key={industry.id} value={industry.id}>{industry.name}</option>
                                ))}
                            </InputField>

                            <InputField
                                placeholder='Название'
                                value={formData.name}
                                maxLength={50}
                                onChange={(e) => handleInputChange(e, 'name')}
                                error={errors.name}
                                errorMessage="Это обязательное поле. Заполните его!"
                            />
                            
                            <InputField
                                placeholder='Бренд'
                                value={formData.brand}
                                onChange={(e) => handleInputChange(e, 'brand')}
                                maxLength={50}
                                error={errors.brand}
                                errorMessage="Это обязательное поле. Заполните его!"
                            />
                            
                            <InputField
                                placeholder='Паушальный взнос'
                                type='number'
                                maxLength={15}
                                value={formData.advertisingFee}
                                onChange={(e) => handleInputChange(e, 'advertisingFee')}
                                error={errors.advertisingFee}
                                errorMessage="Это обязательное поле. Заполните его!"
                                infoType={"BYN"}
                            />
                            <InputField
                                placeholder='Инвестиции'
                                type='number'
                                maxLength={15}
                                value={formData.investments}
                                onChange={(e) => handleInputChange(e, 'investments')}
                                error={errors.investments}
                                errorMessage="Это обязательное поле. Заполните его!"
                                infoType={"BYN"}
                            />
                            
                            <InputField
                                placeholder='Окупаемость'
                                type='number'
                                value={formData.paybackTime}
                                onChange={(e) => handleInputChange(e, 'paybackTime')}
                                error={errors.paybackTime}
                                errorMessage="Это обязательное поле. Заполните его!"
                                infoType={"месяцев"}
                                maxLength={3}
                            />
                        </div>
                        <div className={classes.franchise__column}>
                            <InputField
                                    as='select'
                                    value={formData.typeOfActivityId || ''}
                                    onChange={(e) => handleInputChange(e, 'typeOfActivityId')}
                                    error={errors.typeOfActivityId}
                                    errorMessage="Это обязательное поле. Заполните его!"
                                >
                                    <option value="">Выберите тип активности</option>
                                    {industryStore.getSubIndustries(Number(formData.industryId)).map((type) => (
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    ))}
                            </InputField>

                            <InputField
                                placeholder='Роялти от'
                                value={formData.royalty}
                                type='number'
                                onChange={(e) => handleInputChange(e, 'royalty')}
                                maxLength={4}
                                error={errors.royalty}
                                errorMessage="Это обязательное поле. Заполните его!"
                                infoType={"%"}
                            />
                            <InputField
                                placeholder='Год основания компании'
                                value={formData.companyStartYear}
                                type='number'
                                onChange={(e) => handleInputChange(e, 'companyStartYear')}
                                maxLength={4}
                                error={errors.companyStartYear}
                                errorMessage="Это обязательное поле. Заполните его!"
                                infoType={"год"}
                            />
                            <InputField
                                placeholder='Год основания франшизы'
                                value={formData.franchiseStartYear}
                                type='number'
                                onChange={(e) => handleInputChange(e, 'franchiseStartYear')}
                                maxLength={4}
                                error={errors.franchiseStartYear}
                                errorMessage="Это обязательное поле. Заполните его!"
                                infoType={"год"}
                            />

                            
                            <InputField
                                placeholder='Кол-во собственных точек'
                                type='number'
                                value={formData.ownPoints}
                                maxLength={10}
                                onChange={(e) => handleInputChange(e, 'ownPoints')}
                                error={errors.ownPoints}
                                errorMessage="Это обязательное поле. Заполните его!"
                            />
                            <InputField
                                placeholder='Кол-во франчайзинговых точек'
                                type='number'
                                maxLength={10}
                                value={formData.franchisePoints}
                                onChange={(e) => handleInputChange(e, 'franchisePoints')}
                                error={errors.franchisePoints}
                                errorMessage="Это обязательное поле. Заполните его!"
                            />
                        </div>
                    </div>

                    <div className={classes.descriptionBlock__wrapper}>
                        {descriptionBlocks.map((block, index) => {
                            const { html } = blocksData[index]; 

                            const galleryError = errors[`galleryFiles-${index}`];
                            const descriptionError = errors[`descriptionBlock-${index}`];

                            const error = { 
                                galleryImages: galleryError ? 'Это обязательное поле. Заполните его!' : '',
                                descriptionBlock: descriptionError ? 'Это обязательное поле. Заполните его!' : ''
                            };
                            
                            return (
                                <DescriptionBlock
                                    key={block.id}
                                    index={index}
                                    value={html}
                                    images={galleryFiles[index] || []}
                                    isLast={index === descriptionBlocks.length - 1}
                                    onEditorChange={(index: number, newState: EditorState) => handleEditorStateChange(index, newState)}
                                    onImageChange={(index: number, galleryImages: File[]) => handleImageChange(index, galleryImages)}
                                    onRemove={() => handleRemoveBlock(index)}
                                    onAdd={handleAddBlock}
                                    errors={error}
                                />
                            );
                        })}
                    </div>
                    
                    <div className={classes.franchise__controllers}>
                        <CustomButton onClick={handleSubmit} isCheeseButton>
                            {isEditMode ? "Сохранить изменения" : "Подать объявление"}
                        </CustomButton> 

                        {isEditMode && isMobile &&
                            <CustomButton 
                                onClick={handleDelete}
                                bodyType="empty"
                            >
                                Удалить франшизу
                            </CustomButton>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default FranchiseCreator;
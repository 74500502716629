import $api from "../http/index";
import { IFranchise } from "../models/IFranchise";

export default class UserFranchiseService {
    static async fetchUserFranchises(): Promise<IFranchise[]> {
        try {
            const { data } = await $api.get('users-franchises/get-all');
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async fetchPublicUserFranchises(id: number): Promise<IFranchise[]> {
        try {
            const { data } = await $api.get(`/users-franchises/get-all-public/${id}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async addFranchiseToUser(franchiseId: number): Promise<IFranchise> {
        try {
            const { data } = await $api.post('/users-franchises/add', { franchiseId });
            return data; 
        } catch (error) {
            throw error;
        }
    }

    static async removeFranchise(franchiseId: number): Promise<void> {
        try {
            await $api.delete('/users-franchises/remove', { data: { franchiseId } });
        } catch (error) {
            throw error;
        }
    }
}

import { useState, useEffect, useRef } from "react";
import classes from './SubscribeInput.module.scss';
import CustomButton from "../buttons/CustomButton";
import { useNotification } from "../../hooks/useNotification";
import MailService from "../../service/MailService";
import * as Yup from 'yup'; 

const SubscribeInput = () => {
    const [email, setEmail] = useState("");
    const [isTouched, setIsTouched] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const { notify } = useNotification();

    const emailValidationSchema = Yup.object({
        email: Yup.string()
            .email("Пожалуйста, введите правильный email")
            .required("Email обязателен для заполнения"),
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        if (isTouched && event.target.value.trim() !== "") {
            setIsTouched(false);  
        }
    };

    const handleBlur = () => {
        setIsTouched(true);
    };

    const validateEmail = async () => {
        try {
            await emailValidationSchema.validate({ email });
            return true; 
        } catch (error: any) {
            notify(error.message || "Ошибка при валидации email");
            return false; 
        }
    };

    const handleSubmit = async () => {
        setIsTouched(true);
        const isValid = await validateEmail();
        if (isValid && email.trim() !== "") {
            setIsSubmitted(true);
            await MailService.addEmailToList(email);
            notify("Благодарим за подписку!");
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setIsTouched(false); 
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={classes.subInput}>
            <input
                ref={inputRef}
                type="text"
                placeholder="Введите ваш email"
                className={`${classes.inputField} ${(isTouched && !email.trim()) ? classes.error : ""} ${isSubmitted ? classes.submitted : ""}`}
                value={email}
                maxLength={120}
                onChange={handleInputChange}
                onBlur={handleBlur}
                disabled={isSubmitted}
            />
            <div className={classes.SubscribeButton}>
                <CustomButton onClick={handleSubmit} disabled={isSubmitted}>
                    {isSubmitted ? "Подписка оформлена" : "Подписаться"}
                </CustomButton>
            </div>
        </div>
    );
};

export default SubscribeInput;

import classes from '../cards/RecommendedFranchiseCard.module.scss';
import Skeleton from "./Skeleton";

const SkeletonRecommendedCard = () => {
     return (
          <div className={classes.franchiseCard__container}>
               <Skeleton className={classes.franchiseCard__image}/>
               <div className={classes.franchiseCard__infoWrapper}>
                    <Skeleton width={"180px"} height={26} borderRadius={4}/>
                    <div className={classes.infoBox}>
                         <Skeleton className={classes.infoBox__ownerImage} />
                         <Skeleton width={"30%"} height={26} borderRadius={4}/>
                    </div>
               </div>
          </div>
     );
}

export default SkeletonRecommendedCard;
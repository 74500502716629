import { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";

import NewsObject from './NewsObject/NewsObject';
import Pagination from "../../UI/pagination/Pagination";
import SkeletonNewsObject from "../../UI/skeletons/SkeletonNewsObject";

import classes from './News.module.scss';
import { Helmet } from "react-helmet";
import NothingFound from "../NothingFoundPage/NothingFound";

const News = () => {
     const [currentPage, setCurrentPage] = useState<number>(1);
     const itemsPerPage: number = 3;
 
     const { newsStore } = useContext(Context);
     const { isLoading } = newsStore
     
     useEffect(() => {
          const fetchNews = async () => {
               await newsStore.fetchNews();
          }
          fetchNews();
     }, [newsStore]);
     
     const totalPages: number = Math.ceil(newsStore.News.length / itemsPerPage);
     const startIndex: number = (currentPage - 1) * itemsPerPage;
     const endIndex: number = startIndex + itemsPerPage;
     const currentItems = newsStore.News.slice(startIndex, endIndex);
 
     const handlePageChange = (page: number) => {
         setCurrentPage(page);
     };

     return (
          <>
               <Helmet>
                    <title>Новости - Последние обновления</title>
                    <meta
                         name="description"
                         content="Следите за последними новостями и обновлениями, чтобы быть в курсе всех событий."
                    />
                    <meta name="keywords" content="новости, обновления, события" />
               </Helmet>
               <div className={classes.news__container}>
                    <div className={classes.news__content}>
                         <h1>Новости</h1>
                         <div className={classes.news__items}>
                              {isLoading ? (
                                   Array.from({ length: 3 }).map((_, index) => (
                                        <SkeletonNewsObject index={index} key={index}/>
                                   ))
                              ) : currentItems.length === 0 ? (
                                   <NothingFound text="На данный момемнт нет новстей"/>
                              ) : (
                                   currentItems.map((news, index) => (
                                        <NewsObject
                                             key={news.id}
                                             image={news.img}
                                             newsName={news.title}
                                             date={news.createdAt!}
                                             newsContent={news.details}
                                             index={index}
                                             id={news.id}
                                        />
                                   ))
                              )}
                         </div>
                         {newsStore.News.length >= 3 && (
                              <Pagination
                                   currentPage={currentPage}
                                   totalPages={totalPages}
                                   onPageChange={handlePageChange}
                              />
                         )}
                    </div>
               </div>
          </>
     );
};

export default observer(News);

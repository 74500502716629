import { FC, useState } from 'react';
import { IFranchise } from '../../../models/IFranchise';
import { FRANCHISE_CREATION_ROUTE } from '../../../utilits/consts';

import CustomButton from '../../../UI/buttons/CustomButton';
import ScrollToTopNavLink from '../../../UI/ScrollToTopNavLink';
import NothingFound from '../../NothingFoundPage/NothingFound';
import FranchiseBox from '../../../UI/franchiseBoxes/FranchiseBox';

import classes from './UserFranchiseList.module.scss';
import Pagination from '../../../UI/pagination/Pagination';

interface UserFranchiseListProps {
      userFranchises: Array<IFranchise>;
      isPublicProfile?: boolean;
}

const UserFranchiseList: FC<UserFranchiseListProps> = ({ 
      userFranchises,
      isPublicProfile = false
}) => {
      const [currentPage, setCurrentPage] = useState<number>(1);
      const itemsPerPage: number = 3;
      
      // Фильтруем франшизы по статусу, если это публичный профиль
      const filteredFranchises = isPublicProfile 
            ? userFranchises.filter(franchise => franchise.status !== "Pending" && franchise.status !== "Rejected")
            : userFranchises;

      const totalPages: number = Math.ceil(filteredFranchises.length / itemsPerPage);
      const startIndex: number = (currentPage - 1) * itemsPerPage;
      const endIndex: number = startIndex + itemsPerPage;
      const currentItems = filteredFranchises.slice(startIndex, endIndex);
       
      const handlePageChange = (page: number) => { 
            setCurrentPage(page);
      };

      return (
            <div className={classes.userFranchise}>
                  <div className={classes.userFranchise__content}>
                        {isPublicProfile ? (
                              <>
                                    <div className={classes.userFranchise__header}>
                                          <h1>Франшизы пользователя</h1>
                                    </div>
                                    <div className={classes.userFranchise__box}>
                                          {currentItems.map(franchise => (
                                                <FranchiseBox key={franchise.id} franchise={franchise} isYourProfile={false} />
                                          ))}
                                          {filteredFranchises.length === 0 && (
                                                <NothingFound text={"У пользователя пока нет активных объявлений"} />
                                          )}
                                    </div>
                              </>
                        ) : (
                              <>
                                    <div className={classes.userFranchise__header}>
                                          <h1>Мои объявления</h1>
                                          {userFranchises.length !== 0 && (
                                                <ScrollToTopNavLink to={FRANCHISE_CREATION_ROUTE}>
                                                      <CustomButton bodyType="empty">
                                                            Подать объявление
                                                      </CustomButton>
                                                </ScrollToTopNavLink>
                                          )}
                                    </div>
                                    <div className={classes.userFranchise__box}>
                                          {currentItems.map((franchise) => (
                                                <FranchiseBox key={franchise.id} franchise={franchise} isYourProfile={true} />
                                          ))}

                                          {userFranchises.length === 0 && (
                                                <>
                                                      <NothingFound text={"У вас пока нет активных объявлений. Начните делиться своими возможностями прямо сейчас!"} />
                                                      <ScrollToTopNavLink to={FRANCHISE_CREATION_ROUTE}>
                                                            <CustomButton isCheeseButton style={{ marginLeft: "42%" }}>
                                                                  Подать объявление
                                                            </CustomButton>
                                                      </ScrollToTopNavLink>
                                                </>
                                          )}
                                    </div>
                              </>
                        )}
                        {filteredFranchises.length > itemsPerPage && (
                              <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                              />
                        )}
                  </div>
            </div>
      );
};

export default UserFranchiseList;
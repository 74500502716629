import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import classes from './ErrorDetector.module.scss';
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, RECOVERY_PASSWORD_ROUTE, REGISTRATION_ROUTE } from '../../../../utilits/consts';
import { DirtyFields, FieldErrors } from '../../types/FormFields';


interface ErrorCondition {
     condition: boolean | undefined;
     message: string;
}
 
interface ErrorConditionsByRoute {
     [route: string]: ErrorCondition[];
}

interface ErrorDetectorProps { 
     showError: boolean;
     serverError?: string | null;
     fieldsError: FieldErrors;
     dirtyFields: DirtyFields;
}

const getErrorMessage = ({
     serverError,
     fieldsError,
     dirtyFields,
     currentRoute,
 }: ErrorDetectorProps & { currentRoute: string }): string => {
     const errorConditions: ErrorConditionsByRoute = {
          [LOGIN_ROUTE]: [
               { condition: fieldsError.dataError, message: "Заполните данные" },
               { condition: fieldsError.identifier && dirtyFields.identifier, message: "Неверный идентифкатор" },
               { condition: fieldsError.logging_password && dirtyFields.logging_password, message: "Неверный пароль" },
               { condition: !!serverError, message: serverError || "" },
          ],
          [FORGOT_PASSWORD_ROUTE]: [
               { condition: fieldsError.dataError, message: "Заполните данные" },
               { condition: fieldsError.recovery_email && dirtyFields.recovery_email, message: "Неверная почта" },
               { condition: !!serverError, message: serverError || "" },
          ],
          [REGISTRATION_ROUTE]: [
               { condition: fieldsError.dataError, message: "Заполните данные" },
               { condition: fieldsError.login && dirtyFields.login, message: "Неверный логин" },
               { condition: fieldsError.password && dirtyFields.password, message: "Неверный пароль" },
               { condition: fieldsError.email && dirtyFields.email, message: "Неверная почта" },
               { condition: fieldsError.confirmPassword && dirtyFields.confirmPassword, message: "Пароли не совпадают" },
               { condition: fieldsError.activationCode, message: "Введите код" },
               { condition: !!serverError, message: serverError || "" },
          ],
          [RECOVERY_PASSWORD_ROUTE]: [
               { condition: fieldsError.dataError, message: "Заполните данные" },
               { condition: fieldsError.new_password && dirtyFields.new_password, message: "Неверный пароль" },
               { condition: fieldsError.confirm_newPassword && dirtyFields.confirm_newPassword, message: "Пароли не совпадают" },
               { condition: !!serverError, message: serverError || "" },
          ],
     };
 
     const errors = errorConditions[currentRoute] || [];
 
     const error = errors.find(({ condition }: ErrorCondition) => condition);
     return error ? error.message : "";
};
 
 const ErrorDetector: FC<ErrorDetectorProps> = (props) => {
     const location = useLocation();
     const { showError } = props;
     const currentRoute = location.pathname;
 
     const errorMessage = getErrorMessage({ ...props, currentRoute });
 
     return (
          <>
               {showError && errorMessage && (
                    <div className={classes.ErrorDetected}>
                         {errorMessage}
                    </div>
               )}
          </>
     );
 };
 
 export default ErrorDetector;
import { FC } from 'react';
import { InputProps, TextareaProps, SelectProps, CommonProps } from './types';
import InputComponent from './InputComponent';
import TextareaComponent from './TextareaComponent';
import SelectComponent from './SelectComponent';
import classes from './InputField.module.scss';
import classNames from 'classnames';

type InputFieldProps = (InputProps | TextareaProps | SelectProps) & CommonProps;

const InputField: FC<InputFieldProps> = ({
     as = 'input',
     error = false,
     errorMessage,
     className,
     ...props
}) => {
     const renderField = () => {
          switch (as) {
               case 'textarea':
                    return <TextareaComponent {...(props as TextareaProps)} />;
               case 'select':
                    return <SelectComponent {...(props as SelectProps)} />;
               default:
                    return <InputComponent {...(props as InputProps)} />;
          }
     };

     return (
          <div className={classNames(classes.inputField, { [classes.error]: error }, className)}>
               {renderField()}
               {error && errorMessage && (
                    <div className={classes.errorMessage}>
                         <span>{errorMessage}</span>
                    </div>
               )}
          </div>
     );
};

export default InputField;

import { FC, useState, ChangeEvent, DragEvent } from 'react';
import classes from './MultiplyFileUploader.module.scss';
import addImage from './../../assets/icons/addImage.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { validateImage } from '../../utilits/validateImage';

interface MultiplyFileUploaderProps {
    onImagesChange: (images: File[]) => void;
    error?: boolean;
    errorMessage?: string;
    defaultFiles?: File[];
}

const MAX_IMAGES = 3;

const MultiplyFileUploader: FC<MultiplyFileUploaderProps> = ({ onImagesChange, error, errorMessage, defaultFiles }) => {
    const [selectedImages, setSelectedImages] = useState<File[]>(defaultFiles || []);
    const [dragging, setDragging] = useState(false);
    const [localError, setLocalError] = useState<string | null>(null);

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            try {
                const files = Array.from(event.target.files);
                if (selectedImages.length + files.length > MAX_IMAGES) {
                    setLocalError(`Вы можете загрузить максимум ${MAX_IMAGES} фотографий.`);
                    return;
                }
                const validFiles: File[] = [];
                for (const file of files) {
                    await validateImage(file);
                    validFiles.push(file);
                }
                setSelectedImages(prevImages => {
                    const updatedImages = [...prevImages, ...validFiles];
                    onImagesChange(updatedImages);
                    return updatedImages;
                });
                setLocalError(null);
            } catch (e: any) {
                setLocalError(e.message);
            }
        }
    };

    const removeImage = (index: number) => {
        const updateImages = selectedImages.filter((_, i) => i !== index);
        setSelectedImages(updateImages);
        onImagesChange(updateImages);
    };

    const handleFilesAdded = async (files: File[]) => {
        try {
            if (selectedImages.length + files.length > MAX_IMAGES) {
                setLocalError(`Вы можете загрузить максимум ${MAX_IMAGES} фотографий в один блок.`);
                return;
            }
            const validFiles: File[] = [];
            for (const file of files) {
                await validateImage(file);
                validFiles.push(file);
            }
            setSelectedImages(prevImages => {
                const updatedImages = [...prevImages, ...validFiles];
                onImagesChange(updatedImages);
                return updatedImages;
            });
            setLocalError(null);
        } catch (e: any) {
            setLocalError(e.message);
        }
    };

    //#region Drag and Drop
    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
        if (event.dataTransfer.files) {
            const files = Array.from(event.dataTransfer.files);
            handleFilesAdded(files);
        }
    };
    //#endregion

    const uniqueId = `multipleFileUploader_${Math.random()}`;
    return (
        <div
            className={`${classes.multipleFileUploader} ${dragging ? classes.dragging : ''} ${error || localError ? classes.error : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                type="file"
                id={uniqueId}
                onChange={handleFileChange}
                multiple
                className={classes.fileInput}
            />

            {(localError || error) && <p className={classes.errorMessage}>{localError || errorMessage}</p>}
            {selectedImages.length > 0 ? (
                <Swiper
                    className={classes.swiper__uploader}
                    slidesPerView={'auto'} 
                    spaceBetween={10} 
                    freeMode={true} 
                >
                    {selectedImages.map((file, index) => (
                        <SwiperSlide key={file.name + index} className={classes.swiper_slide}>
                            <div className={classes.swiperImage_container} onClick={() => removeImage(index)}>
                                <img src={URL.createObjectURL(file)} alt={`Выбран ${file.name}`} />
                            </div>
                        </SwiperSlide>
                    ))}
                    {selectedImages.length < MAX_IMAGES && (
                        <SwiperSlide className={classes.swiper_slide}>
                            <label htmlFor={uniqueId}>
                                <img src={addImage} alt="Добавить изображение" />
                            </label>
                        </SwiperSlide>
                    )}
                </Swiper>
            ) : (
                <label className={classes.dropZone} htmlFor={uniqueId}>
                    <p>Добавьте или переместите <span>свои файлы сюда</span></p>
                </label>
            )}
        </div>
    );
};

export default MultiplyFileUploader;

import { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';

import LoadingDots from '../../UI/loadingDots';

import classes from './AndminPageForms.module.scss';
import CustomButton from '../../UI/buttons/CustomButton';
import MultiSelect from '../../UI/selectors/MultiSelect';
import { useNotification } from '../../hooks/useNotification';
import ScrollToTopNavLink from '../../UI/ScrollToTopNavLink';
import { FRANCHISE_RECOVERY_ROUTE } from '../../utilits/consts';

const FranchiseStatusManager = () => {
     const { franchiseStore, franchiseApprovalStore } = useContext(Context);
     const [selectedFranchises, setSelectedFranchises] = useState<number[]>([]);
     const { notify, showLoading, hideLoading } = useNotification();

     const handleFranchisesChange = (newSelectedFranchises: number[]) => {
          setSelectedFranchises(newSelectedFranchises);
     };

     const handleSelectedValuesChange = (newSelectedValues: number[]) => {
          setSelectedFranchises(newSelectedValues);
          handleFranchisesChange(newSelectedValues); 
     };

     const options = franchiseStore.franchises.map(franchise => ({
          value: franchise.id,
          label: franchise.name,
     }));

     const handleSubmit = async () => {
          try {
               showLoading('Убираем франшизу');
               await franchiseApprovalStore.removeFranchiseFromPool(selectedFranchises);
               hideLoading();
               notify('Франшиза перемещена на вкладку убранных');
          } catch (error) {
               alert('Произошла ошибка при удалении франшизы');
          }
     };

     return (
          <div className={classes.adminDashboard__container}>
               <div className={classes.adminDashboard__content}>
                    <div className={classes.adminToolsPanel}>
                         <div className={classes.adminToolBox}>
                              <h1>Выбрать франшизу, которую хотите убрать</h1>
                              <div className={classes.adminToolBox__controllers}>
                                   <MultiSelect
                                        options={options}
                                        selectedValues={selectedFranchises}
                                        onChange={handleSelectedValuesChange}
                                        limit={10}                     
                                        enableSearch={true}          
                                   />
                              </div>
                              <CustomButton onClick={handleSubmit}>
                                   Убрать франшизу
                              </CustomButton>
                         </div>
                    </div>
                    <div className={classes.adminToolBox}>
                         <ScrollToTopNavLink to={FRANCHISE_RECOVERY_ROUTE}>
                              <CustomButton>
                                   Неиспользуемые франшизы
                              </CustomButton>
                         </ScrollToTopNavLink>
                    </div>
               </div>
          </div>
     );
};

export default FranchiseStatusManager;

import { makeAutoObservable, runInAction, toJS } from "mobx";
import { IFranchise } from "../models/IFranchise";
import FranchiseService from "../service/FranchiseService";
import { IDescriptionBlock } from "../models/IDescriptionBlock";
import FranchiseApprovalService from "../service/FranchiseApprovalService";

export default class FranchiseStore {
     private _franchises: IFranchise[] = [];
     private _recommendedFranchises: IFranchise[] = [];

     private _totalCount: number = 0;
     private _page: number = 1;
     private _limit: number | undefined = 10;
     private _filters = {
          industryId: [] as number[],
          typeOfActivityId: [] as number[],
          budget: "Все" as string,
          sort: "По популярности" as string
     };
     private _loading: boolean = false;

     constructor() {
          makeAutoObservable(this);
          this.fetchRecommendedFranchises();
          this.fetchInitialData();
     }

     setFilters(industryIds: number[], typeOfActivityIds: number[], budget: string, sort: string) {
          this._filters = { industryId: industryIds, typeOfActivityId: typeOfActivityIds, budget, sort };
          this.fetchCatalogPage(1);
     }

     fetchCatalogPage(page: number) {
          this._page = page;
          this.fetchFranchises(page);
     }

     async fetchFranchises(page: number = this._page, limit: number | undefined = this._limit, fetchAll: boolean = false) {
          this.setLoading(true);
          try {
               const { franchises, totalCount } = await FranchiseService.fetchFranchises(
                    this._filters.industryId,
                    this._filters.typeOfActivityId,
                    fetchAll ? 1 : page, 
                    fetchAll ? undefined : limit,
                    this._filters.budget,
                    this._filters.sort,
                    fetchAll
               );
               runInAction(() => {
                    this._franchises = franchises;
                    this._totalCount = totalCount;
                    if (!fetchAll) {
                         this._page = page;
                         this._limit = limit;
                    }
               });
          } catch (error) {
              console.error("Не удалось получить данные о франшизах:", error);
          } finally {
              this.setLoading(false);
          }
     }
     
     setLimit(limit: number | undefined) {
          this._limit = limit;
     }

     async fetchInitialData() {
          this.setLoading(true);
          try {
               let { franchises, totalCount } = await FranchiseService.fetchFranchises(
                    undefined,
                    undefined,
                    1,
                    this.limit,
                    "Все",
                    "По популярности"
               );
               runInAction(() => {
                    this._franchises = franchises;
                    this._totalCount = totalCount;
               });
          } catch (error) {
               console.error("Не удалось получить данные о франшизах:", error);
          } finally {
               this.setLoading(false);
          }
     }
  
     async fetchRecommendedFranchises() {
          try {
               const recommendedFranchises = await FranchiseService.fetchRecommendedFranchises();
               this._recommendedFranchises = recommendedFranchises; 
          } catch (error) {
               console.error("Не удалось получить рекомендованные франшизы:", error);
               return { franchises: [] };
          }
     }

     async setRecommendedFranchises(newRecommendedFranchises: number[]) {
          try {
               const recommendedFranchises = await FranchiseService.setRecommendedFranchises(newRecommendedFranchises);
               this._recommendedFranchises = recommendedFranchises; 
          } catch (error) {
               console.error("Не удалось получить рекомендованные франшизы:", error);
               return { franchises: [] };
          }
     }
     
     async fetchOneFranchise(id: number): Promise<IFranchise> {
          this.setLoading(true);
          try {
               const franchise = await FranchiseService.fetchOneFranchise(id);
               return franchise;
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          }
          finally {
               this.setLoading(false);
          }
     }

     async fetchFranchisesByIndustry(id:number, industryId:number, limit: number) {
          this.setLoading(true);
          try {
               return await FranchiseService.fetchFranchisesByIndustry(id, industryId, limit);
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          } finally {
               this.setLoading(false);
          }
     }

     async fetchFranchiseByReleaseId(releaseId: number): Promise<IFranchise> {
          try {
               const franchise = await FranchiseService.fetchFranchiseByReleaseId(releaseId);
               return franchise;
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          }
     }

     async createFranchise ( 
          form: {
               name: string,
               royalty: string,
               advertisingFee: string,
               investments: string,
               ownPoints: string,
               franchisePoints: string,
               paybackTime: string,
               industryId: string,
               typeOfActivityId: string,
               brand: string,
               faceImage: File,
               companyStartYear: string;
               franchiseStartYear: string;
               descriptionBlocks: IDescriptionBlock[]
          },
     ) {
          try {
               await FranchiseService.createFranchise(form);
          } catch (error) {
               console.error("Не удалось создать франшизу:", error);
          }
     }
     
     async updateFranchise (
          id: string,
          form : {
               name: string,
               royalty: string,
               advertisingFee: string,
               investments: string,
               ownPoints: string,
               franchisePoints: string,
               paybackTime: string,
               industryId: string,
               typeOfActivityId: string,
               brand: string,
               faceImage: File,
               companyStartYear: string;
               franchiseStartYear: string;
               descriptionBlocks: IDescriptionBlock[]
          }
     ) {
          try {
               await FranchiseService.updateFranchise(id, form);
          } catch (error) {
               console.error("Не удалось обновить франшизу:", error);
          }
     }

     async deleteOneFranchiseById(id: number): Promise<IFranchise> {
          try {
               const message = await FranchiseService.deleteOneFranchise(id);
               return message;
          } catch (error) {
               throw error;
          }
     }

     setLoading(bool: boolean) {
          this._loading = bool;
     }

     setTotalCount(count:any) {
          this._totalCount = count
     }

     get franchises(): IFranchise[] {
          return toJS(this._franchises);
     }

     get totalCount() {
          return this._totalCount
     }
     
     get page() {
          return this._page
     }
     
     get recommendedFranchises() : IFranchise[] {
          return toJS(this._recommendedFranchises)
     }

     get limit() {
          return this._limit
     }

     get isLoading() {
          return this._loading;
     }
}
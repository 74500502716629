import React, { useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import classes from './GallerySwiper.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import ImageModal from '../modals/ImageModal';
import { Pagination } from 'swiper/modules';
import useResponsive from '../../hooks/useResponsive';

interface GallerySwiperProps {
    images: string[];
}

const GallerySwiper: React.FC<GallerySwiperProps> = ({ images }) => {
    const sliderRef = useRef<SwiperRef>(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const { isMobile } = useResponsive();

    const handlePrev = () => sliderRef.current?.swiper.slidePrev();
    const handleNext = () => sliderRef.current?.swiper.slideNext();

    const updateNavigationState = () => {
        if (sliderRef.current) {
            setIsBeginning(sliderRef.current.swiper.isBeginning);
            setIsEnd(sliderRef.current.swiper.isEnd);
        }
    };

    return (
          <div className={`${classes.gallery} ${images.length === 1 ? classes.singleImage : ''}`}>
               <Swiper
                    breakpoints={{
                         768: { slidesPerView: 1 },
                         1000: { slidesPerView: 2 },
                         1600: { slidesPerView: 3 },
                    }}
                    spaceBetween={"5vw"}
                    freeMode={true}
                    loop={isMobile ? true : false}
                    onSlideChange={updateNavigationState}
                    onReachBeginning={() => setIsBeginning(true)}
                    onReachEnd={() => setIsEnd(true)}
                    modules={[Pagination]}
                    pagination={isMobile ? { clickable: true } : false}
                    className={classes.gallerySwiper}
                    ref={sliderRef}
               >
               {images.map((imageUrl, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={imageUrl}
                            alt=""
                            onClick={() => setSelectedImage(imageUrl)}
                            className={classes.swiperImage}
                        />
                    </SwiperSlide>
               ))}
               </Swiper>
               {!isMobile && (
                    <div className={classes.customNavigation}>
                         <button
                         className={classes.prevButton}
                         onClick={handlePrev}
                         disabled={isBeginning}
                         ></button>
                         <button
                         className={classes.nextButton}
                         onClick={handleNext}
                         disabled={isEnd}
                         ></button>
                    </div>
               )}
               {selectedImage && (
                    <ImageModal image={selectedImage} onClose={() => setSelectedImage(null)} />
               )}
        </div>
    );
};

export default GallerySwiper;
import { FC } from 'react';
import ScrollToTopNavLink from '../ScrollToTopNavLink';
import classes from './QuestionnaireButton.module.scss';
import cheeseForQuestionnaire from '../../assets/UI/cheeseForQuestionnaire.svg';
import { QUESTIONARY_ROUTE } from '../../utilits/consts';

interface QuestionnaireButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
    onClick?: () => void;
}

const QuestionnaireButton: FC<QuestionnaireButtonProps> = ({ onClick, ...props }) => {
     return (
          <ScrollToTopNavLink 
               to={QUESTIONARY_ROUTE} 
               onClick={onClick}
               {...props}
          >
               <button className={classes.questionnaireButton}>
                    <span>Пройти анкету</span>
                    <div className={classes.cheeseUI}>
                         <img src={cheeseForQuestionnaire} alt="" />
                    </div>
               </button>
          </ScrollToTopNavLink>
     );
}

export default QuestionnaireButton;
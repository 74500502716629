import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import FilterButton from '../buttons/FilterButton';
import classes from './FilterSwiper.module.scss';
import { Context } from '../../index';

interface FilterSwiperProps {
    filters: {
        showAllCategories: boolean;
        categories: number[];
    };
    handleFilterChange: (id: number, isActive: boolean) => void;
}

const FilterSwiper: React.FC<FilterSwiperProps> = ({ filters, handleFilterChange }) => {
    const { industryStore } = useContext(Context);
    
    return (
        <Swiper
            className={classes.filtersSwiper}
            spaceBetween={10}
            slidesPerView="auto"
            freeMode={true}
        >
            {/* "Все категории" кнопка */}
            <SwiperSlide>
                    <FilterButton
                            isActive={filters.showAllCategories}
                            onClick={() => handleFilterChange(0, true)}
                        >
                        Все категории
                    </FilterButton>
            </SwiperSlide>

            {/* Генерация кнопок для всех индустрий */}
            {industryStore.industries.map((industry) => (
                <SwiperSlide key={industry.id}>
                    <FilterButton
                        key={industry.id}
                        isActive={filters.categories.includes(industry.id)}
                        onClick={() =>
                            handleFilterChange(
                                industry.id,
                                !filters.categories.includes(industry.id)
                            )
                        }
                    >
                        {industry.name}
                    </FilterButton>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default FilterSwiper;

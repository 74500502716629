import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
    const isSmallDesktop = useMediaQuery({ minWidth: 1025, maxWidth: 1349 }); 
    const isMediumDesktop = useMediaQuery({ minWidth: 1350, maxWidth: 1599 }); 
    const isLargeDesktop = useMediaQuery({ minWidth: 1600 });

    return { isMobile, isTablet, isSmallDesktop, isMediumDesktop, isLargeDesktop };
};

export default useResponsive;

import { Helmet } from 'react-helmet';
import classes from './Contacts.module.scss';

const Contacts = () => {
    return (
        <>
            <Helmet>
                <title>Контактная информация</title>
                <meta name="description" content="Контактная информация для связи с нами: телефон, электронная почта, адрес." />
            </Helmet>
            <div className={classes.contacts__container}>
                <div className={classes.contacts__content}>
                    <div className={classes.contacts__card}>
                        <h1>Контактная информация</h1>
                        <div className={classes.cardInfo__box}>
                            <div className={classes.cardInfo__item}>
                                <span>ИП</span>
                                <p>Трубченко Никита Сергеевич</p>
                            </div>
                            <div className={classes.cardInfo__item}>
                                <span>Звонить по номеру</span>
                                <p><a href="tel:+375336949638" aria-label="Позвонить по номеру +375336949638">+375336949638</a></p>
                            </div>
                            <div className={classes.cardInfo__item}>
                                <span>Мы находимся по адресу</span>
                                <p>г. Полоцк, ул. Петра Машерова 9а</p>
                            </div>
                            <div className={classes.cardInfo__item}>
                                <span>Электронная почта</span>
                                <p><a href="mailto:partners@francheese.by" aria-label="Отправить email на partners@francheese.by">partners@francheese.by</a></p>
                            </div>
                            <div className={classes.cardInfo__item}>
                                <span>УНП</span>
                                <p>391965816</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contacts;

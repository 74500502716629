import { FC, useContext, useState } from "react";
import classes from './CatalogFranchiseCard.module.scss';
import { FRANCHISE_PAGE_ROUTE,PUBLIC_PROFILE_ROUTE } from "../../utilits/consts";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { IFranchise } from "../../models/IFranchise";
import { ReactComponent as Heart } from './../../assets/icons/heart.svg';
import { ReactComponent as TimeIcon } from './../../assets/icons/vector.svg';
import { Context } from "../../index";
import { STATIC_URL } from "../../http";
import { formatPrice } from "../../utilits/utilities";
import DefaultImage from '../../assets/defaultUser.png'
import { useNotification } from "../../hooks/useNotification";
import Skeleton from "../skeletons/Skeleton";

const CatalogFranchiseCard: FC<{ franchise: IFranchise }> = ({ franchise }) => {
    const { favoritesStore, userStore } = useContext(Context);
    const [isFavorite, setIsFavorite] = useState<boolean>(favoritesStore.favorites.some(fav => fav.id === franchise.id));
    const [isImageLoaded, setIsImageLoaded] = useState(true);
    const { notify } = useNotification();

    const ownerInfo = franchise?.ownerInfo;
    const ownerImage = ownerInfo?.img ? `${STATIC_URL}/users/${ownerInfo.img}` : DefaultImage;

    const imageUrl = franchise?.faceImage ? `${STATIC_URL}/franchises/${franchise.faceImage}` : '';
  
    const toggleFavorite = async () => {
        if(!userStore.isAuth) {
            notify("Авторизуйтесь для добавления франшизы в избранное")
            return;
        }

        try {
            if (isFavorite) {
                setIsFavorite(false);
                await favoritesStore.removeFavorite(franchise.id);
            } else {
                setIsFavorite(true);
                await favoritesStore.addFavorite(franchise.id);
            }
        } catch (error) {
            console.error("Не удалось обновить статус избранного:", error);
        }
    };

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    const handleImageError = () => {
        setIsImageLoaded(false); 
    };

    return (
        <div className={classes.card__container}>
            <div className={classes.card__imagesBox}>
                {!isImageLoaded ? (
                    <Skeleton className={classes.franchise__image} borderRadius={12}/>
                ) : (
                    <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`} className={classes.franchise__image}>
                        <img
                            src={imageUrl}
                            alt="Franchise Image"
                            onLoad={handleImageLoad} 
                            onError={handleImageError}
                        />
                    </ScrollToTopNavLink>
                )}

                <div className={classes.userImage__box}>
                    <ScrollToTopNavLink to={`${PUBLIC_PROFILE_ROUTE}/${ownerInfo?.id}`}>
                        <img src={ownerImage} alt={`Author image for ${ownerInfo?.firstName}`} />
                    </ScrollToTopNavLink>
                </div>
            </div>

            <div className={classes.card__info}>
                <p>{franchise.name}</p>
                <div className={classes.price_and_time_box}>
                    <div className={classes.price}>
                        <span>от {formatPrice(franchise.investments)} BYN</span>
                    </div>
                    <div className={classes.time}>
                        <TimeIcon className={classes.timeIcon}/>
                        <span>от {franchise.paybackTime} месяцев</span>
                    </div>
                </div>
                <div className={classes.buttons_container}>
                    <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`}>
                        <span className={classes.details}>
                            Подробнее
                        </span>
                    </ScrollToTopNavLink>
                    <div className={isFavorite ? classes.favorite : ''} onClick={toggleFavorite} style={{cursor: "pointer"}}>
                        <Heart className={classes.card__icon}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CatalogFranchiseCard;
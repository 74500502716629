import React from "react";
import AuthorizationInput from "../../../UI/inputs/AuthorizationInput";

import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/icons/eye-off.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';

interface LoginFormProps {
    identifier: string;
    identifierError?: boolean;
    password: string;
    passwordError?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    blurHandle: (event: React.FocusEvent<HTMLInputElement>) => void;
    isSubmitClicked: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
    identifier,
    identifierError,
    password,
    passwordError,
    handleChange,
    blurHandle,
    isSubmitClicked,
}) => {
    return (
        <>
            <AuthorizationInput
                type="text"
                placeholder="Логин или почта"
                name="identifier"
                value={identifier}
                onBlur={blurHandle}
                onChange={handleChange}
                error={isSubmitClicked && identifierError}
                icon={<EmailIcon/>}
            />
            <AuthorizationInput 
                type="password" 
                placeholder="Пароль" 
                name="logging_password"
                value={password}
                onBlur={blurHandle}
                onChange={handleChange}
                toggleIcon={<EyeIcon/>}
                toggleIconActive={<EyeOffIcon/>}
                error={isSubmitClicked && passwordError}
                isPasswordField
            />
        </>
    );
}

export default LoginForm;

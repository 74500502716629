import { useContext, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

import banner from '../../assets/advertisingBanners/banner.png';
import ProfileCard from "./ProfileCards/ProfileCard";
import UserFranchiseList from "./UserFranchiseList/UserFranchiseList";

import classes from "./Profile.module.scss";
import { Helmet } from "react-helmet";

const Profile = () => {
     const { userStore, userFranchiseStore } = useContext(Context);
 
     useEffect(() => {
          const loadUserData = async () => {
               await userFranchiseStore.loadUserFranchises();
          };
          loadUserData();
     }, [userFranchiseStore, userStore]);

     const userFranchises = userFranchiseStore.userFranchises;

     return (
          <>
               <Helmet>
                    <title>Профиль пользователя - {userStore.user.firstName}</title>
                    <meta
                         name="description"
                         content={`Личный кабинет пользователя ${userStore.user.firstName}. Здесь вы можете редактировать свои данные и управлять вашими франшизами.`}
                    />
               </Helmet>
               <div className={classes.profile}>
                    <div className={classes.profile__content}>
                         <div className={classes.profile__wrapper}>
                              <div className={classes.userData__box}>
                                   <h1>Добрый день, {userStore.user.firstName}!</h1>
                                   <ProfileCard />
                              </div>
                              <div className={classes.advertising_banner}>
                                   <img src={banner} alt="" />
                              </div>
                         </div>
                    </div>
                    <UserFranchiseList userFranchises={userFranchises} />
               </div>
          </>
     );
}

export default observer(Profile);
import { FC, useState, useEffect, ButtonHTMLAttributes, ReactNode } from "react";
import classes from './FilterButton.module.scss';

interface FilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    onClick: () => void;
    isActive: boolean;
}

const FilterButton: FC<FilterButtonProps> = ({ children, onClick, isActive, ...props }) => {
    const [active, setActive] = useState(isActive);

    useEffect(() => {
        setActive(isActive);
    }, [isActive]);

    const handleClick = () => {
        setActive(!active);
        onClick();
    };

    const buttonClassName = active ? `${classes.filterButton} ${classes.active}` : `${classes.filterButton} ${classes.inactive}`;

    return (
        <button className={buttonClassName} onClick={handleClick} {...props}>
            {children}
        </button>
    );
};

export default FilterButton;

import { BrowserRouter } from "react-router-dom";
import AppRouter from '../AppRouter';
import { NotificationProvider } from '../hooks/useNotification';

const App = () => {
     return (
          <NotificationProvider>
               <BrowserRouter>
                    <AppRouter />
               </BrowserRouter>
          </NotificationProvider>
     );
}

export default App;

import { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";

import App from './components/App';
import UserStore from './store/UserStore';
import FranchiseStore from './store/FranchiseStore';
import FavoritesStore from './store/FavoritesStore';
import UserFranchiseStore from './store/UserFranchiseStore';
import NewsStore from './store/NewsStore';
import IndustryStore from './store/IndustryStore';
import TypeOfActivityStore from './store/TypeOfActivityStore';
import ReleaseStore from './store/ReleaseStore';
import FranchiseApprovalStore from './store/FranchiseApprovalStore';
import FranchiseDescriptionStore from './store/FranchiseDescriptionStore';

Sentry.init({
     dsn: "https://ff0138cef3adb2a6fe69c411452d4d95@o4508535509221376.ingest.de.sentry.io/4508539638906960",
     integrations: [
       Sentry.browserTracingIntegration(),
       Sentry.replayIntegration(),
     ],
     // Tracing
     tracesSampleRate: 1.0, //  Capture 100% of the transactions
     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
     // Session Replay
     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
   });

interface State {
     userStore: UserStore;
     franchiseStore: FranchiseStore;
     favoritesStore: FavoritesStore;
     newsStore: NewsStore;
     userFranchiseStore: UserFranchiseStore;
     industryStore: IndustryStore;
     typeOfActivityStore: TypeOfActivityStore;
     releaseStore: ReleaseStore;
     franchiseApprovalStore: FranchiseApprovalStore
     franchiseDescriptionStore: FranchiseDescriptionStore
}

const favoritesStore = new FavoritesStore();
const userFranchiseStore = new UserFranchiseStore();
const userStore = new UserStore(favoritesStore, userFranchiseStore);
const franchiseStore = new FranchiseStore();
const newsStore = new NewsStore();
const typeOfActivityStore = new TypeOfActivityStore();
const industryStore = new IndustryStore(typeOfActivityStore);
const releaseStore = new ReleaseStore();
const franchiseApprovalStore = new FranchiseApprovalStore();
const franchiseDescriptionStore = new FranchiseDescriptionStore();

export const Context = createContext<State>({
     userStore,
     franchiseStore,
     favoritesStore,
     newsStore,
     userFranchiseStore,
     industryStore,
     typeOfActivityStore,
     releaseStore,
     franchiseApprovalStore,
     franchiseDescriptionStore
});

const root = ReactDOM.createRoot(
     document.getElementById('root') as HTMLElement
);

root.render(
     <Context.Provider value={{
          userStore,
          franchiseStore,
          favoritesStore,
          userFranchiseStore,
          newsStore,
          industryStore,
          typeOfActivityStore,
          releaseStore,
          franchiseApprovalStore,
          franchiseDescriptionStore
     }}>
     <App />
     </Context.Provider>
);
import classes from './BuyAndSell.module.scss';

import FirstImage  from '../../../../assets/buyAndSell/BuyAndSell-image-1.png'
import SecondImage  from '../../../../assets/buyAndSell/BuyAndSell-image-2.png';
import ThirdImage  from '../../../../assets/buyAndSell/BuyAndSell-image-3.png';

import CustomButton from "../../../../UI/buttons/CustomButton";
import { REGISTRATION_ROUTE } from "../../../../utilits/consts";
import ScrollToTopNavLink from "../../../../UI/ScrollToTopNavLink";
import '../../../../index'
import { useContext } from 'react';
import { Context } from '../../../../index';
import { observer } from 'mobx-react-lite';

const BuyAndSell = () => {
    const {userStore} = useContext(Context)
    
    if(userStore.isAuth) {
        return null;
    } 

    return (
        <div className={classes.buyAndSell__container}>
            <div className={classes.buyAndSell__content}>
                <div className={classes.buyAndSell__imagesWrapper}>
                    <div className={classes.firstImage}>
                        <img src={FirstImage} alt="Покупайте и продовайте 1"></img>
                    </div>
                    <div className={classes.secondImage}>
                        <img src={SecondImage} alt="Покупайте и продовайте 2"></img>
                    </div>
                    <div className={classes.thirdImage}>
                        <img src={ThirdImage} alt="Покупайте и продовайте 3"></img>
                    </div> 
                </div>  
                <div className={classes.buyAndSell__text}>
                    <h1>Продавайте и покупайте франшизы</h1>
                    <p>Разверните свой бизнес на новые горизонты с francheese! Откройте новые возможности вместе с нами и достигайте значительных высот. Зарегистрируйтесь сейчас, чтобы сделать первый шаг к успеху!</p>
                    <ScrollToTopNavLink to={REGISTRATION_ROUTE}>
                        <CustomButton>Зарегистрироваться</CustomButton>
                    </ScrollToTopNavLink>
                </div>
            </div>
        </div>
    )};

export default observer(BuyAndSell);
import { FC } from "react";
import classes from './RecommendedFranchiseCard.module.scss';
import { IFranchise } from "../../models/IFranchise";
import { STATIC_URL } from "../../http";
import { formatPrice } from "../../utilits/utilities";
import { FRANCHISE_PAGE_ROUTE, PUBLIC_PROFILE_ROUTE } from "../../utilits/consts";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { ReactComponent as TimeIcon } from '../../assets/icons/timeDuration.svg';
import defaultUserImage from '../../assets/defaultUser.png'

interface RecommendedFranchiseCardProps {
    franchise: IFranchise;
}

const RecommendedFranchiseCard: FC<RecommendedFranchiseCardProps> = ({
    franchise
}) => {
    const ownerInfo = franchise?.ownerInfo;
    const ownerImage = ownerInfo ? `${STATIC_URL}/users/${ownerInfo.img}` : defaultUserImage;
  
    return (
        <div className={classes.franchiseCard__container}>
            <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`}>
                <div 
                    className={classes.franchiseCard__image}
                    style={{ backgroundImage: `url(${STATIC_URL}/franchises/${franchise?.faceImage})` }}
                />
            </ScrollToTopNavLink>

            <div className={classes.franchiseCard__infoWrapper}>
                <span>{franchise?.name}</span>
                <div className={classes.infoBox}>
                    <ScrollToTopNavLink to={`${PUBLIC_PROFILE_ROUTE}/${ownerInfo?.id}`} className={classes.infoBox__ownerImage}>
                        <img 
                            src={ownerImage}
                            alt={ownerInfo?.firstName} 
                            onError={(e) => (e.currentTarget.src = defaultUserImage)}
                        />
                    </ScrollToTopNavLink>
                    <div className={classes.infoBox__price}>
                        {formatPrice(franchise.investments)} BYN
                    </div>
                    <div className={classes.infoBox__paybackTime}>
                        <TimeIcon/>
                        <span>{franchise.paybackTime} мес.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecommendedFranchiseCard;

import { FC } from 'react';
import classes from './NewsObject.module.scss';
import CustomButton from "../../../UI/buttons/CustomButton";
import { STATIC_URL } from "../../../http";
import { formatDate } from "../../../utilits/formatDate";
import ScrollToTopNavLink from "../../../UI/ScrollToTopNavLink";
import { NEWS_PAGE_ROUTE } from "../../../utilits/consts";
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg';

interface NewsObjectProps {
    image: string;
    date: string;
    newsContent: string;
    newsName: string;
    index: number;
    id: number
}

const NewsObject: FC<NewsObjectProps> = ({ image, date, newsContent, newsName, index, id }) => {
    return (
        <div className={`${classes.newsItem__container} ${index % 2 === 0 ? classes.even : classes.odd}`}>
            <img src={`${STATIC_URL}/news/${image}`} alt={newsName} className={classes.news__image}/>
            <div className={classes.content__box}>
                <h2>{newsName}</h2>
                <p className={classes.news__creationTime}>
                    <Clock />
                    {formatDate(date!)}
                </p>
                <p  className={classes.news__description} dangerouslySetInnerHTML={{ __html: newsContent }}></p>
                <div className={classes.read__button}>
                    <ScrollToTopNavLink to={`${NEWS_PAGE_ROUTE}/${id}`} className={classes.toFranchisePage}>
                        <CustomButton>
                            Читать
                        </CustomButton>
                    </ScrollToTopNavLink>
                </div>
            </div>
        </div>
    );
};

export default NewsObject;

import { FC } from "react";
import classes from '../cards/FranchiseCard.module.scss'
import EditorsChoiceImg from '../../assets/UI/editors_choice.svg';
import Skeleton from "./Skeleton";

interface FranchiseCardProps {
     displayName?: boolean,
     displayOwner?: boolean,
     isEditorsChoice?: boolean,
 }
 
const SkeletonsFranchiseCard: FC<FranchiseCardProps> = ({
     displayName = true,
     displayOwner = true,
     isEditorsChoice = false
 }) => {
     return (
          <div className={classes.franchise__card}>
               <Skeleton className={classes.franchise__faceImage} borderRadius={24} contrast={"dark"}/>
               
               {isEditorsChoice && (
                    <div className={classes.editorsChoice}>
                         <img src={EditorsChoiceImg} alt="Выбор редакции" />
                    </div>
               )}

               <div className={classes.franchise__userProfile}>
                    {displayName && (
                         <Skeleton width={"70%"} height={30} borderRadius={4}/>
                    )}
                    {displayOwner && (
                         <div className={classes.franchise__author}>
                              <Skeleton width={35} height={35} borderRadius={"100%"}/>
                              <Skeleton width={"50%"} height={30} borderRadius={4}/>
                         </div>
                    )}
               </div>
               <Skeleton className={classes.franchise__performance} height={"20%"}/>
          </div>
     )
}

export default SkeletonsFranchiseCard;
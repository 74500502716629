import { ContentState, convertFromHTML, convertFromRaw, EditorState } from 'draft-js';
import MultiplyFileUploader from '../../../UI/fileUploaders/MultiplyFileUploader';
import DraftEditor from '../../../UI/inputs/DraftEditor';
import classes from './DescriptionBlock.module.scss';
import { FC, useEffect, useState } from 'react';

interface DescriptionBlockProps {
    index: number;
    value: string;
    images: File[];
    isLast: boolean;
    onEditorChange: (index: number, newState: EditorState) => void;
    onImageChange: (index: number, galleryImages: File[]) => void;
    onRemove: (index: number) => void;
    onAdd: (index: number) => void;
    errors?: Record<string, string>;
}

const DescriptionBlock: FC<DescriptionBlockProps> = ({
    index,
    value,
    images,
    isLast,
    onEditorChange,
    onImageChange,
    onRemove,
    onAdd,
    errors
}) => {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    useEffect(() => {
        const blocksFromHTML = convertFromHTML(value);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
    }, [value]);

    const handleEditorContentChange = (state: EditorState) => {
        setEditorState(state);
        onEditorChange(index, state);
    };

    const [galleryFiles, setGalleryFiles] = useState<File[]>(images);
    const handleImagesChange = (newImages: File[]) => {
        setGalleryFiles(newImages); 
        onImageChange(index, newImages);
    };

    let galleryError = errors?.['galleryImages'] ? true : false; 
    let descriptionError = errors?.['descriptionBlock'] ? true : false; 
    
    return (
        <div className={classes.descriptionBlock}>
            <div className={classes.descriptionBlock__header}>
                <h2>Описание</h2>
                <div className={classes.descriptionBlock__deleteButton}>
                    {index > 0 && (
                        <button className={classes.removeButton} onClick={() => onRemove(index)} title='Удалить блок описания'>
                            -
                        </button>
                    )}
                </div>
            </div>
            
            <DraftEditor
                editorState={editorState}
                onEditorStateChange={handleEditorContentChange}
                error={descriptionError}
                errorMessage="Это обязательное поле. Заполните его!"
                maxLength={4000}
            />
            
            <h2>Фотографии</h2>
            <MultiplyFileUploader
                onImagesChange={handleImagesChange} 
                defaultFiles={galleryFiles} 
                error={galleryError}
                errorMessage="Это обязательное поле. Заполните его!"
            />

            <div className={classes.descriptionBlock__controllers}>
                {isLast && index !== 6 ? (
                    <button className={classes.addButton} onClick={() => onAdd(index)} title='Создать блок описания'>
                        +
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default DescriptionBlock;
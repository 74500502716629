import React from "react";
import AuthorizationInput from "../../../UI/inputs/AuthorizationInput";

import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/icons/eye-off.svg';

interface RecoveryPasswordProps {
     new_password: string;
     new_passwordError?: boolean;
     
     confirm_newPassword: string;
     confirm_newPasswordError?: boolean;

     blurHandle: (event: React.FocusEvent<HTMLInputElement>) => void;
     handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
     isSubmitClicked: boolean;
}

const RecoveryPassword: React.FC<RecoveryPasswordProps> = ({
     new_password,
     new_passwordError,

     confirm_newPassword,
     confirm_newPasswordError,

     blurHandle,
     handleChange,
     isSubmitClicked,
}) => {
     return (
          <>
              <AuthorizationInput 
                    type="password" 
                    placeholder="Пароль" 
                    name="new_password"
                    value={new_password}
                    onBlur={blurHandle}
                    onChange={handleChange}
                    error={isSubmitClicked && new_passwordError}
                    toggleIcon={<EyeIcon />}
                    toggleIconActive={<EyeOffIcon />}
                    hintMessage="Пароль должен содержать как минимум:"
                    hints={[
                         "8 символов",
                         "1 цифру",
                         "1 строчную букву"
                    ]}
                    isPasswordField
               />

               <AuthorizationInput
                    type="password" 
                    placeholder="Повторите пароль" 
                    name="confirm_newPassword"
                    value={confirm_newPassword}
                    onBlur={blurHandle}
                    onChange={handleChange}
                    error={isSubmitClicked && confirm_newPasswordError}
                    toggleIcon={<EyeIcon />}
                    toggleIconActive={<EyeOffIcon />}
                    isPasswordField
               />
          </>
     );
}

export default RecoveryPassword;

import { FC } from "react";
import classes from "./Agreement.module.scss";
import ScrollToTopNavLink from "./ScrollToTopNavLink";
import { PRIVACY_POLICY_ROUTE } from "../utilits/consts";

interface AgreementProps {
    isLogin: boolean;
    isThirdStep: boolean;
}

const Agreement: FC<AgreementProps> = ({ isLogin, isThirdStep }) => {
    return (
        <>
           {(isLogin || (!isLogin && isThirdStep)) && (
                <div className={classes.agreement}>
                    {isThirdStep ? "Регистрируясь" : "Авторизируясь"}, я соглашаюсь с правилами Francheese и даю свое согласие на <ScrollToTopNavLink to={PRIVACY_POLICY_ROUTE}>обработку персональных данных.</ScrollToTopNavLink>
                </div>
            )}
        </>
    );
}

export default Agreement;

import { makeAutoObservable } from "mobx";
import { INews } from "../models/INews";
import NewsService from "../service/NewsService";

export default class NewsStore {
     private _News: INews[] = [];
     isLoading = false;

     constructor() {
          makeAutoObservable(this);
     }

     setLoading(bool: boolean) {
          this.isLoading = bool;
     }

     async fetchNews() {
          this.setLoading(true);
          try {
               const news = await NewsService.fetchNews();
               this._News = news;
          } catch (error: any) {
               console.error("Ошибка загрузки новостей", error);
          } finally {
               this.setLoading(false);
          }
     }

     addNews(news: INews[]) {
          this._News = [...this._News, ...news];
     }

     async createNews (    
          title: string, 
          img: File,
          details: string,
          content:string,)
          {
          try {
               const NewNews = await NewsService.createNews(title, img, details, content);
               this._News.push(NewNews);
          } catch (error: any) {
               console.error("Ошибка добавления новости  ", error);
          }
     }

     async fetchOneNews(id: number): Promise<INews> {
          this.setLoading(true);
          try {
               const OneNews = await NewsService.fetchOneNews(id);
               return OneNews;
          } catch (error) {
               console.error("Не удалось получить новость:", error);
               throw error;
          } finally {
               this.setLoading(false);
          }
     }

     async deleteOneNews(id: number): Promise<INews> {
          try {
               const response = await NewsService.deleteOne(id);
               return response;
          } catch (error) {
               console.error("Не удалось удалить новость:", error);
               throw error;
          }
     }
     
     get News(): INews[] {
          return this._News;
     }
}
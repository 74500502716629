import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { IUser } from "../../models/IUser";
import { NOTHING_FOUND_ROUTE, PROFILE_ROUTE } from "../../utilits/consts";
import { Helmet } from "react-helmet";

import LoadingDots from "../../UI/loadingDots";
import UserFranchiseList from "./UserFranchiseList/UserFranchiseList";
import PublicProfileCard from "./ProfileCards/PublicProfileCard";
import banner from '../../assets/advertisingBanners/banner.png';

import classes from "./Profile.module.scss";

const PublicProfile = () => {
     const { userStore, userFranchiseStore } = useContext(Context);
     const { isLoading } = userStore;
     const { id } = useParams<{ id: string }>();
     const [selectedUser, setSelectedUser] = useState<IUser>();
     const navigate = useNavigate();
     
     useEffect(() => {
          if (id) {
               const fetchUserData = async () => {
                    const fetchedUser = await userStore.fetchUserById(parseInt(id));
          
                    if (!fetchedUser) {
                         navigate(NOTHING_FOUND_ROUTE);
                         return;
                    }
          
                    if (fetchedUser.id === userStore.user.id) {
                         navigate(PROFILE_ROUTE);
                         return;
                    }
          
                    setSelectedUser(fetchedUser);
               };
          
               fetchUserData();
               userFranchiseStore.loadPublicUserFranchises(id);
          }
     }, [id, userFranchiseStore, userStore]);
     
     
     const userFranchises = userFranchiseStore.userFranchises;

     
     if (isLoading || !selectedUser) {
          return <LoadingDots />
     }

     return (
          <>
               <Helmet>
                    <title>Профиль {selectedUser.firstName} - Открывайте новые возможности</title>
                    <meta
                         name="description"
                         content={`Изучите профиль пользователя ${selectedUser.firstName}. Узнайте о его франшизах, опыте и предложениях.`}
                    />
               </Helmet>
               <div className={classes.profile}>
                    <div className={classes.profile__content}>
                         <div className={classes.profile__wrapper}>
                              <div className={classes.userData__box}>
                                   <h1>Профиль пользователя {selectedUser.firstName}</h1>
                                   <PublicProfileCard user={selectedUser}/>
                              </div>
                              <div className={classes.advertising_banner}>
                                   <img src={banner} alt="" />
                              </div>
                         </div>
                    </div>

                    <UserFranchiseList userFranchises={userFranchises} isPublicProfile/>
               </div>
          </>
     );
}

export default observer(PublicProfile);

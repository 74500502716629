import { useContext, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper';
import { Pagination } from 'swiper/modules';
import classes from './InteractiveCardsSwiper.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import FranchiseCard from '../cards/FranchiseCard';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import ScrollToTopNavLink from '../ScrollToTopNavLink';
import { FRANCHISE_PAGE_ROUTE } from '../../utilits/consts';

const InteractiveCardsSwiper = () => {
    const sliderRef = useRef<SwiperCore | null>(null);
    const { releaseStore } = useContext(Context);

    return (
        <div className={classes.franhises__galery}>
            <Swiper
                className={classes.franhises__galerySwiper}
                onSwiper={(swiper: SwiperCore) => {
                    sliderRef.current = swiper;
                }}
                loop={true} 
                modules={[Pagination]}
                pagination={{
                    clickable: true,
                    // dynamicBullets: true,
                }}
                // initialSlide={1}
                spaceBetween={'50vw'} 
            >
                {releaseStore.threeInteractiveCards.map((franchise) => (
                    <SwiperSlide key={franchise.id} className={classes.galerySwiper__item}>
                        <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`}>
                            <FranchiseCard 
                                franchise={franchise}
                                displayOwner={false}
                            />
                        </ScrollToTopNavLink>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default observer(InteractiveCardsSwiper);

import { FC } from 'react';
import { SelectProps } from './types';
import classes from './InputField.module.scss';

const SelectComponent: FC<SelectProps> = (props) => (
     <div className={classes.selectWrapper}>
          <select {...props} className={classes.input} />
          <div className={classes.customArrow} />
     </div>
);

export default SelectComponent;

import { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import NothingFound from "../NothingFoundPage/NothingFound";
import FavoritesBox from "../../UI/franchiseBoxes/FavoritesBox";
import SkeletonFranchiseBox from "../../UI/skeletons/SkeletonFranchiseBox";
import classes from './Favorites.module.scss';
import { Helmet } from "react-helmet";

const Favorites = () => {
    const { favoritesStore, franchiseStore } = useContext(Context);
    const { favorites, isLoading } = favoritesStore;
    
    useEffect(() => {
        const loadFavorites = async () => {
            await favoritesStore.loadFavorites();
        };
    
        loadFavorites();
    }, [favoritesStore, franchiseStore.franchises]);
    

    const toggleFavorite = async (franchiseId: number) => {
        try {
            if (favoritesStore.favorites.some(fav => fav.id === franchiseId)) {
                await favoritesStore.removeFavorite(franchiseId);

            } else {
                await favoritesStore.addFavorite(franchiseId);
            }
        } catch (error) {
            console.error("Не удалось обновить статус избранных:", error);
        }
    };

    if (isLoading) {
        return (
            <div className={classes.favorites__container}>
                <div className={classes.favorites__content}>
                    <h1>Избранное</h1>
                    <div className={classes.favorites__items}>
                        <SkeletonFranchiseBox />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Избранное - Франшизы, которые вам интересны</title>
                <meta name="description" content="Посмотрите все франшизы, которые вы добавили в избранное." />
            </Helmet>
            <div className={classes.favorites__container}>
                <div className={classes.favorites__content}>
                    <h1>Избранное</h1>
                    {favoritesStore.favorites.length === 0 ? (
                        <NothingFound text={"У вас пока нет избранных"} />
                    ) : (
                        <div className={classes.favorites__items}>
                            {favorites.map(franchise => (
                                <FavoritesBox key={franchise.id} franchise={franchise} onFavoriteToggle={toggleFavorite} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default observer(Favorites);

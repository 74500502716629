import { FC, useContext, useEffect, useState } from 'react';
import MultiSelect from './MultiSelect';
import { Context } from '../../index';

interface FranchiseSelectionProps {
    onFranchisesChange: (selectedFranchises: number[]) => void;
}

const FranchiseSelection: FC<FranchiseSelectionProps> = ({ onFranchisesChange }) => {
    const { franchiseStore } = useContext(Context);
    const [selectedFranchises, setSelectedFranchises] = useState<number[]>([]);

    const options = franchiseStore.franchises.map(franchise => ({
        value: franchise.id,
        label: franchise.name,
    }));

    const recommendedFranchises = franchiseStore.recommendedFranchises.map(franchise => franchise.id);

    useEffect(() => {
        setSelectedFranchises(recommendedFranchises);
    }, []);

    const handleSelectedValuesChange = (newSelectedValues: number[]) => {
        setSelectedFranchises(newSelectedValues);
        onFranchisesChange(newSelectedValues); 
    };

    return (
        <MultiSelect
            options={options}
            selectedValues={selectedFranchises}
            onChange={handleSelectedValuesChange}
            limit={6}                     
            enumerate={true}              
            enableSearch={true}          
        />
    );
};

export default FranchiseSelection;

import { FC, useState, ChangeEvent, useEffect } from 'react';
import classes from './FileUploader.module.scss';
import { validateImage } from '../../utilits/validateImage';

interface FileUploaderProps {
    onImageChange: (image: File) => void;
    error?: boolean;
    errorMessage?: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    defaultImageURL?: string;
    disabled?: boolean;
    reset?: boolean;
    isUserImageUploader?: boolean;
}

const FileUploader: FC<FileUploaderProps> = ({
    onImageChange,
    error,
    errorMessage,
    width,
    height,
    borderRadius,
    defaultImageURL,
    isUserImageUploader = false,
    disabled,
}) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [localError, setLocalError] = useState<boolean>(false);
    const [localErrorMessage, setLocalErrorMessage] = useState<string | null>(null);
    const [imageURL, setImageURL] = useState<string | null>(null);

    useEffect(() => {
        setImageURL(defaultImageURL || null);
        setSelectedImage(null);
        setLocalError(false);
        setLocalErrorMessage(null);
    }, [defaultImageURL]);

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;

        try {
            if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                if(isUserImageUploader) {
                    await validateImage(file, 100, 100, 4000, 4000);
                }
                else {
                    await validateImage(file);
                }
                setSelectedImage(file);
                setImageURL(URL.createObjectURL(file));
                onImageChange(file);
                setLocalError(false);
                setLocalErrorMessage(null);
            }
        } catch (e: any) {
            setLocalError(true);
            setLocalErrorMessage(e.message);
        }
    };

    return (
        <div
            className={`${classes.fileUploader} ${localError || error ? classes.error : ''}`}
            style={{ width, height }}
        >
            <label 
                htmlFor="fileUploader"
                className={disabled ? classes.disabled : error ? classes.error : '' }
                style={{borderRadius}}
            >
                {selectedImage || imageURL ? (
                    <div className={classes.imagePreview}>
                        <img
                            src={selectedImage ? URL.createObjectURL(selectedImage) : imageURL || ''}
                            alt="Selected"
                            className={classes.previewImage}
                        />
                    </div>
                ) : (
                    <span>Загрузить изображение</span>
                )}
            </label>
            <input
                type="file"
                id="fileUploader"
                onChange={handleFileChange}
                className={classes.fileInput}
                disabled={disabled}
            />
            {(localError || error) && (
                <p className={classes.errorMessage}>{localErrorMessage || errorMessage}</p>
            )}
        </div>
    );
};

export default FileUploader;

import { FC } from "react";
import classes from './IndustryDropdownButtons.module.scss';
import FilterButton from "./FilterButton";

interface Type {
    id: number;
    name: string;
}

interface Industry {
    id: number;
    name: string;
    types: Type[];
}

interface IndustryDropdownButtonsProps {
    industry: Industry;
    selectedTypes: number[];
    onTypeChange: (type: number) => void;
    expandedIndustry: number[];
    toggleIndustryDropdown: (industryId: number) => void;
}

const IndustryDropdownButtons: FC<IndustryDropdownButtonsProps> = ({
    industry, selectedTypes, onTypeChange, expandedIndustry, toggleIndustryDropdown
}) => {
    const isExpanded = expandedIndustry.includes(industry.id);

    return (
        <div className={classes.industryDropdown__container}>
               <button 
                    className={`${classes.industry__button} ${isExpanded ? classes.industry__button__expanded : ''}`} 
                    onClick={() => toggleIndustryDropdown(industry.id)}
               >
                    {industry.name}
               </button>
               {isExpanded && (
                    <div className={classes.types__dropdown}>
                         {industry.types.map((type) => (
                         <FilterButton
                              key={type.id}
                              isActive={selectedTypes.includes(type.id)}
                              onClick={() => onTypeChange(type.id)}
                         >
                              {type.name}
                         </FilterButton>
                         ))}
                    </div>
               )}
        </div>
    );
};

export default IndustryDropdownButtons;
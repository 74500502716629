import { FC, CSSProperties } from 'react';
import classes from './Skeleton.module.scss';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
     height?: string | number;
     width?: string | number;
     borderRadius?: string | number;
     contrast?: 'light' | 'dark';
}

const Skeleton: FC<SkeletonProps> = ({
     height,
     width,
     borderRadius,
     contrast = 'light',
     className,
     ...props
}) => {
     const skeletonStyle: CSSProperties = {
          height: height || 'inherit',
          width: width || 'inherit',
          borderRadius: borderRadius || '0',
          ...props.style, // Разрешает переопределение стилей
     };

     const contrastClass = contrast === 'dark' ? classes.skeletonDark : classes.skeletonLight;

     return (
          <div 
               className={`${classes.skeleton} ${contrastClass} ${className}`}
               style={skeletonStyle}
               {...props}
          ></div>
     );
}

export default Skeleton;
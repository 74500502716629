import { FC, MouseEvent as ReactMouseEvent } from "react";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { FRANCHISE_PAGE_ROUTE } from "../../utilits/consts";
import { STATIC_URL } from "../../http";
import { IFranchise } from "../../models/IFranchise";
import classes from './SearchItem.module.scss';
import SearchIcon from '../../assets/icons/search.svg';

interface SearchItemProps {
    franchise: IFranchise;
    onClick: (event: ReactMouseEvent) => void;
}

const SearchItem: FC<SearchItemProps> = ({ franchise, onClick }) => {
    return (
        <div className={classes.searchItem__wrapper} onClick={onClick}>
            <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`} className={classes.searchItem__body}>
                <img className={classes.franchiseImage} src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt="" />
                <p>{franchise.name}</p>
                <img className={classes.searchIcon} src={SearchIcon} alt="Поиск" />
            </ScrollToTopNavLink>
        </div>
    );
};

export default SearchItem;

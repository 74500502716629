import React, { useEffect, useContext, useState } from "react";
import classes from './FranchisesYouMayLike.module.scss';
import { Context } from "../../../index";
import CatalogFranchiseCard from "../../../UI/cards/CatalogFranchiseCard";
import { IFranchise } from "../../../models/IFranchise";

interface FranchisesYouMayLikeProps {
     franchiseId: number;
     industryId: number;
}

const FranchisesYouMayLike: React.FC<FranchisesYouMayLikeProps> = ({
     franchiseId,
     industryId
}) => {
     const { franchiseStore } = useContext(Context);
     const [franchises, setFranchises] = useState<IFranchise[]>([]);

     useEffect(() => {
          const fetchFranchises = async () => {
               const fracnhises = await franchiseStore.fetchFranchisesByIndustry(franchiseId, industryId, 3);
               setFranchises(fracnhises);
          }
          fetchFranchises()
     }, [ franchiseStore]);

     if (!franchiseStore.recommendedFranchises || franchiseStore.recommendedFranchises.length === 0) { return null; }

     return (
          <div className={classes.franchisesYouMayLike}>
               <div className={classes.franchisesYouMayLike__content}>
                    <h1>Рекомендуем</h1>
                    <div className={classes.franchisesYouMayLike__cards}>
                         {franchises.map((franchise) => (
                              <CatalogFranchiseCard key={franchise.id} franchise={franchise} />
                         ))}
                    </div>
               </div>
          </div>
     )
}

export default FranchisesYouMayLike;
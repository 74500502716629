import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { FORGOT_PASSWORD_ROUTE, HOME_ROUTE, LOGIN_ROUTE, RECOVERY_PASSWORD_ROUTE, REGISTRATION_ROUTE } from "../../utilits/consts";
import { FormFields, DirtyFields, FieldErrors } from "./types/FormFields";
import { useNotification } from "../../hooks/useNotification";
import { Helmet } from "react-helmet";
import { IUser } from "../../models/IUser";

import Agreement from "../../UI/Agreement";
import ErrorDetector from "./AuthComponents/ErrorDetector/ErrorDetector";
import CustomButton from "../../UI/buttons/CustomButton";
import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";
import ComeBackButton from "../../UI/buttons/ComeBackButton";

import MailService from "../../service/MailService";

import RegistrFirstStep from "./AuthComponents/RegistrFirstStep";
import LoginForm from "./AuthComponents/LoginForm";
import RecoveryPassword from "./AuthComponents/RecoveryPassword";
import RegistrSecondStep from "./AuthComponents/RegistrSecondStep";
import RegistrThirdStep from "./AuthComponents/RegistrThirdStep";
import ForgotPassword from "./AuthComponents/ForgotPassword";

import NothingFound from "../NothingFoundPage/NothingFound";

import AuthCheeseImg from '../../assets/UI/authCheese.svg';

import classes from './Auth.module.scss';

//#region Interfaces
const allFormFieldsTemplate: FormFields = {
    login: "",
    password: "",
    confirmPassword: "",
    recovery_email: "",
    firstName: "",
    lastName: "",
    email: "",
    identifier: "",
    activationCode: "",
    new_password: "",
    confirm_newPassword: "",
    logging_password: "",
    consent: false,
    profileImage: null,
};

const generateDirtyFields = <T extends Record<string, any>>(template: T): DirtyFields => {
    const dirtyFields: DirtyFields = {} as DirtyFields;

    Object.keys(template).forEach(key => {
        dirtyFields[key as keyof DirtyFields] = false;
    });

    return dirtyFields;
};

const generateFieldErrors = <T extends Record<string, any>>(template: T): FieldErrors => {
    const fieldErrors: FieldErrors = {} as FieldErrors;

    Object.keys(template).forEach(key => {
        fieldErrors[key as keyof FieldErrors] = false; 
    });

    return fieldErrors;
};

function generateInitialFormState<T extends Record<string, any>>(template: T): T {
    const initialState: Record<string, any> = {};

    Object.keys(template).forEach(key => {
        if (typeof template[key] === 'string') {
            initialState[key] = ""; 
        } else if (typeof template[key] === 'boolean') {
            initialState[key] = false; 
        } else if (template[key] === null) {
            initialState[key] = null; 
        } else {
            initialState[key] = undefined; 
        }
    });

    return initialState as T;
}

const initialFormState = generateInitialFormState(allFormFieldsTemplate);
const initialDirtyFields = generateDirtyFields(allFormFieldsTemplate);
const initialFieldErrors = generateFieldErrors(allFormFieldsTemplate);
//#endregion

const Auth = () => {
    const navigate = useNavigate();
    const { notify } = useNotification();
    const { userStore } = useContext(Context);

    const location = useLocation();
    const isLogin = location.pathname === LOGIN_ROUTE;
    const isForgotPassword = location.pathname === FORGOT_PASSWORD_ROUTE;
    const isRegistration = location.pathname === REGISTRATION_ROUTE;
    const isRecoveryPassword = location.pathname === RECOVERY_PASSWORD_ROUTE;

    const [showError, setShowError] = useState<boolean>(false);
    const [serverError, setServerError] = useState<string | null>(null);
    const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(1);

    const [formState, setFormState] = useState<FormFields>(initialFormState);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>(initialFieldErrors);
    const [dirtyFields, setDirtyFields] = useState<DirtyFields>(initialDirtyFields);

    //#region RecoveryAccount
    const [user, setUser] = useState<IUser | null>(null);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const userString = params.get('user');

        if (userString) {
            try {
                const user = JSON.parse(decodeURIComponent(userString));
                console.log(user)
                setUser(user);
            } catch (error) {
                console.error('Ошибка при декодировании данных пользователя:', error);
            }
        }
    }, [isRecoveryPassword]);
    //#endregion

    //#region Handlers
    const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDirtyFields(prev => ({ ...prev, [name]: true }));
        validateField(name, value);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormState(prevState => {
            const newState = { ...prevState, [name]: value };
            return newState;
        });
    };

    const handleCheckboxChange = () => {
        setFormState((prevState) => ({
            ...prevState,
            consent: !prevState.consent
        }));
   };
   
    const handleImageChange = useCallback((image: File) => {
        setFormState(prev => ({ ...prev, profileImage: image }));
        if (formState.profileImage !== null) {
            setFieldErrors(prev => ({ ...prev, profileImage: false }));
        }
    }, [formState.profileImage]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setShowError(false);
        setIsSubmitClicked(true);
        
        const hasErrors = validateForm();
        if (hasErrors) {
            setShowError(true);
            return;
        }

        setIsLoading(true);
        try {
            if (isLogin) {
                await userStore.login(formState.identifier, formState.logging_password);
                navigate(HOME_ROUTE);
            }
               
            if (isRegistration) {
                if (currentStep === 1) {
                    await userStore.checkLogin(formState.login);
                    setServerError(null);
                    setCurrentStep(2);
                } else if (currentStep === 2) {
                    await userStore.checkEmail(formState.email);
                    await MailService.sendActivationCode(formState.email);
                    setServerError(null);
                    setCurrentStep(3);
                } else if (currentStep === 3) {
                    await userStore.registration(formState.email, formState.password, formState.login, formState.firstName, formState.lastName, formState.activationCode, formState.profileImage as File);
                    setServerError(null);
                    navigate(HOME_ROUTE);
                }
            }

            if (isForgotPassword){
                await MailService.sendRecoveryLink(formState.recovery_email);
                notify(`Письмо было отправлено на почту ${formState.recovery_email}`)
            }

            if (isRecoveryPassword && user) {
                await userStore.changePassword(user.id, formState.new_password);
                navigate(HOME_ROUTE);
            }
        } catch (error: any) {
            setServerError(error.message);
            setShowError(true);
        } finally {
            setIsSubmitClicked(false);
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            resetErrors();
            setCurrentStep(prevStep => prevStep - 1);
        } else {
            navigate(-1);
        }
    }
    //#endregion
   
    //#region Validation
    const validateField = useCallback((name: string, value: string) => {
        setFieldErrors(prevErrors => {
            const error = (() => {
                switch (name) {
                    case 'login':
                        return !/^(?!\d+$)[a-zA-Z\d]{4,}$/.test(value);
                    case 'recovery_email':
                    case 'email':
                        return !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value);
                    case 'new_password':
                    case 'password':
                        return !/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value);
                    default:
                        return false;
                }
            })();
            return { ...prevErrors, [name]: error };
        });
    }, []);

    const validateForm = useCallback((): boolean => {
        const errors: FieldErrors = { ...initialFieldErrors, dataError: false};

        if (isLogin) {
            errors.identifier = !formState.identifier;
            errors.logging_password = !formState.logging_password;
            errors.dataError = errors.identifier || errors.logging_password;
        }

        if (isRegistration) {
            if (currentStep === 1) {
                errors.login = !formState.login || fieldErrors.login;
                errors.password = !formState.password || fieldErrors.password;
                errors.confirmPassword = !formState.confirmPassword || formState.confirmPassword !== formState.password;
                errors.dataError = !formState.login || !formState.password || !formState.confirmPassword;
            } else if (currentStep === 2) {
                errors.firstName = !formState.firstName;
                errors.lastName = !formState.lastName;
                errors.email = !formState.email || fieldErrors.email;
                errors.dataError = !formState.firstName || !formState.lastName || !formState.email;
            } else if (currentStep === 3) {
                errors.activationCode = !formState.activationCode || fieldErrors.activationCode;
                errors.consent = formState.consent === false;
                if(errors.consent) {
                    notify('Пожалуйста, согласитесь на обработку данных');
                }
            }
        }

        if (isForgotPassword) {
            errors.recovery_email = !formState.recovery_email || fieldErrors.recovery_email;
            errors.dataError = !formState.recovery_email;
        }

        if (isRecoveryPassword) {
            errors.new_password = !formState.new_password || fieldErrors.new_password;
            errors.confirm_newPassword = !formState.confirm_newPassword || formState.confirm_newPassword !== formState.new_password;
            errors.dataError = !formState.new_password || !formState.confirm_newPassword;
        }

        setFieldErrors(errors);
        return Object.values(errors).some(error => error);

    }, [formState, fieldErrors, currentStep, isLogin]);
    
    //#endregion
    
    //#region ResetErrors
    const resetErrors = () => {
        setShowError(false);
        setServerError(null);
        setDirtyFields(initialDirtyFields);
        setFieldErrors(initialFieldErrors);
    };

    useEffect(() => {
        resetErrors();
    }, [isLogin])
    //#endregion

    //#region StepsConroller
    const renderRegistrationSteps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <RegistrFirstStep
                            login={formState.login}
                            loginError={fieldErrors.login}
                            password={formState.password}
                            passwordError={fieldErrors.password}
                            confirmPassword={formState.confirmPassword}
                            confirmPasswordError={fieldErrors.confirmPassword}
                            blurHandle={handleBlur}
                            handleChange={handleChange}
                            isSubmitClicked={isSubmitClicked}
                        />
                        <ScrollToTopNavLink to={LOGIN_ROUTE} className={classes.AuthLinks}>
                            Уже есть аккаунт
                        </ScrollToTopNavLink>
                    </>
                );

            case 2:
                return (
                    <RegistrSecondStep
                        profileImage={formState.profileImage}
                        profileImageError={fieldErrors.profileImage}
                        firstName={formState.firstName}
                        firstNameError={fieldErrors.firstName}
                        lastName={formState.lastName}
                        lastNameError={fieldErrors.lastName}
                        email={formState.email}
                        emailError={fieldErrors.email}
                        handleImageChange={handleImageChange}
                        blurHandle={handleBlur}
                        handleChange={handleChange}
                        isSubmitClicked={isSubmitClicked}
                    />
                );

            case 3:
                return (
                    <RegistrThirdStep
                        activationCode={formState.activationCode}
                        consent={formState.consent}
                        handleCheckboxChange={handleCheckboxChange}
                        activationCodeError={fieldErrors.activationCode}
                        storedEmail={formState.email}
                        blurHandle={handleBlur}
                        handleChange={handleChange}
                        isSubmitClicked={isSubmitClicked}
                    />
                );

            default:
                return null;
        }
    };

    const getFormTitle = () => {
        if (isLogin) return "Войти";
        if (isForgotPassword) return "Забыли пароль";
        if (isRecoveryPassword) return "Восстановление пароля";
        return "Регистрация";
    };
    
    const getFormDescription = () => {
        if (isLogin) return "И снова здравствуй";
        if (isForgotPassword) 
            return "Мы все иногда забываем пароли. Введите свой email, чтобы получить код для восстановления доступа к аккаунту.";
        if (isRecoveryPassword && user) 
            return (
                <span>
                    Добро пожаловать, <strong>{user.firstName}</strong>! Введите новый пароль и повторите его для подтверждения.
                </span>
            )
        switch (currentStep) {
            case 1: return "Заполните информацию об аккаунте";
            case 2: return "Заполните основную информацию о себе";
            default: return "Вы почти у цели. Мы отправили письмо с кодом на почту...";
        }
    };
    //#endregion
    
    if(isRecoveryPassword && !user) {
        return (
            <div className={classes.auth__container}>
                <NothingFound text="Сылка для восстановления пароля более недействительна"/>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>{getFormTitle()}</title>
                <meta name="description" content={getFormDescription().toString()} />
            </Helmet>
            
            <div className={classes.auth__container}>
                <div className={classes.auth__wrapper}>
                    <ComeBackButton onBack={handleBack} />
                    <form onSubmit={handleSubmit}>
                        <div className={classes.form__content}>
                            {isRegistration && (
                                <div className={classes.stepNumeration}>
                                    <img src={AuthCheeseImg} alt="Авторизация" />
                                    <span>{currentStep}</span>
                                </div>
                            )}
            
                            <h2>{getFormTitle()}</h2>
                            <p>{getFormDescription()}</p>

                            {isLogin && (
                                <>
                                    <LoginForm
                                        identifier={formState.identifier}
                                        identifierError={fieldErrors.identifier}
                                        password={formState.logging_password}
                                        passwordError={fieldErrors.logging_password}
                                        handleChange={handleChange}
                                        blurHandle={handleBlur}
                                        isSubmitClicked={isSubmitClicked}
                                    />
                                    <ScrollToTopNavLink to={FORGOT_PASSWORD_ROUTE} className={classes.AuthLinks}>
                                        Забыл пароль
                                    </ScrollToTopNavLink>
                                </>
                            )}

                            {isForgotPassword && (
                                <ForgotPassword 
                                    email={formState.recovery_email}
                                    emailError={fieldErrors.recovery_email}
                                    blurHandle={handleBlur}
                                    handleChange={handleChange}
                                    isSubmitClicked={isSubmitClicked}
                                />
                            )}

                            {isRecoveryPassword && (
                                <>
                                    <RecoveryPassword
                                        new_password={formState.new_password}
                                        new_passwordError={fieldErrors.new_password}
                                        confirm_newPassword={formState.confirm_newPassword}
                                        confirm_newPasswordError={fieldErrors.confirm_newPassword}
                                        handleChange={handleChange}
                                        blurHandle={handleBlur}
                                        isSubmitClicked={isSubmitClicked}
                                    />
                                </>
                            )}

                            {isRegistration && renderRegistrationSteps()}
                        </div>
                        
                        <div className={classes.submit__container}>
                            <ErrorDetector
                                showError={showError}
                                fieldsError={fieldErrors}
                                dirtyFields={dirtyFields}
                                serverError={serverError}
                            />

                            <div className={classes.submitButton}>
                                <CustomButton
                                    type="submit"
                                    loading={isLoading}
                                >
                                {isLogin ? "Войти" :
                                    isForgotPassword ? "Отправить письмо" :
                                    currentStep === 3 ? "Зарегистрироваться" :
                                    "Продолжить"
                                }
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                    <Agreement isLogin={isLogin} isThirdStep={currentStep === 3} />
                </div>
            </div>
        </>
    );
};

export default observer(Auth);
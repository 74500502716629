import $api from "../http/index";

export default class MailService {
    static async sendFranchiseRequest(fullName: string, email: string, phone: string, message: string, franchiseId: number) {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('fullName', fullName);
            formDataToSend.append('email', email);
            formDataToSend.append('phone', phone);
            formDataToSend.append('message', message);
            formDataToSend.append('franchiseId', franchiseId.toString());
    
            return await $api.post('/mails/send-franchise-request', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            throw error;
        }
    };

    static async sendFranchiseApprovalStatus(fullName: string, email: string, message: string, franchiseId: number) {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('fullName', fullName);
            formDataToSend.append('email', email);
            formDataToSend.append('message', message);
            formDataToSend.append('franchiseId', franchiseId.toString());
    
            return await $api.post('/mails/send-franchise-request', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            throw error;
        }
    };
    
    static async sendQuestionnaireResult(formData: {
        fullName: string;
        phone: string;
        email: string;
        city: string;
        franchiseInterest: string;
        investmentAmount: string;
        businessExperience: string;
        leadershipExperience: string;
        franchiseExperience: string;
        hasPremises: string;
        notes: string;
    }) {
        try {
            return await $api.post('/mails/send-questionnaire-result', formData);
        } catch (e: any) {
            throw new Error(e.response?.data?.message);
        }
    }
    
    static async sendActivationCode(email: string) {
        try {
                return await $api.post('/mails/send-activation-code', { email });
        } catch (e: any) {
                throw new Error(e.response?.data?.message);
        }
    }

    static async sendRecoveryLink(email: string) {
        try {
                return await $api.post('/mails/send-recovery-link', { email });
        } catch (e: any) {
                throw new Error(e.response?.data?.message);
        }
    }

    static async getAllSubscribers() {
        try {
            const response = await $api.get('/mails/get-all-subscribers')
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }

    static async addEmailToList(email: string) {
        try {
            return await $api.post('/mails/add-email-to-list', {
                email: email
            });
        } catch (error) {
            throw error;
        }
    };

    static async sendFranchiseApproved(userId: number, franchiseId: number) {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('userId', userId.toString());
            formDataToSend.append('franchiseId', franchiseId.toString());

            return await $api.post('/mails/send-franchise-approved', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message);
        }
   }

   static async sendFranchiseDenied(userId: number, franchiseId: number) {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('userId', userId.toString());
            formDataToSend.append('franchiseId', franchiseId.toString());

            return await $api.post('/mails/send-franchise-denied', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message);
        }
   }
};
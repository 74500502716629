import { CSSProperties, FC, ButtonHTMLAttributes, MouseEvent } from "react";
import classNames from 'classnames';
import classes from './CustomButton.module.scss';

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    bodyType?: 'filled' | 'empty';
    padding?: string;
    borderRadius?: string;
    fontSize?: string;
    loading?: boolean;
    width?: 'fit-content' | '100%';
    isCheeseButton?: boolean;
}

const CustomButton: FC<CustomButtonProps> = ({
    children,
    bodyType = 'filled',
    padding,
    borderRadius,
    fontSize,
    loading,
    width,
    isCheeseButton,
    className,
    ...props
}) => {
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            props.onClick(event);
        }
    };

    const buttonStyle: CSSProperties = {
        padding,
        borderRadius,
        fontSize,
        width,
        ...props.style,
    };

    const buttonClasses = classNames(
        classes.customButton,
        {
            [classes.empty]: bodyType === 'empty',
            [classes.cheeseButton]: isCheeseButton,
        },
        className
    );

    return (
        <button
            {...props}
            className={buttonClasses}
            style={buttonStyle}
            onClick={handleClick}
            disabled={loading}
        >
            {loading ? 
                <span className={classes.spinner}></span>
                : children
            }

            {isCheeseButton && (
                <>
                    <span className={classes.circle}></span>
                    <span className={classes.circle}></span>
                    <span className={classes.circle}></span>
                </>
            )}
        </button>
    );
};

export default CustomButton;